import { createContext, useState, useEffect, Context } from 'react';

import api from '../models/api';
import { me } from '../models/user';
import { UserDTO } from '../models/user';

interface AuthContext {
  token?: string | null;
  refresh?: string | null;
  setToken: Function;
  setRefresh: Function;
  user: UserDTO | null;
  setUser: Function;
  logout: () => void;
}

const defaultState = {
  setToken: (val: string) => {},
  setRefresh: (val: string) => {},
  user: null,
  setUser: (val: string) => {},
  logout: () => {},
};

export const authContext: Context<AuthContext> =
  createContext<AuthContext>(defaultState);

export function AuthProvider({ children }: any) {
  const [token, setToken]: [string | null, Function] = useState(null);
  const [refresh, setRefresh]: [string | null, Function] = useState(null);
  const [user, setUser]: [UserDTO | null, Function] = useState(null);

  const logout = () => {
    setToken(null);
    window.localStorage.removeItem('token');
    setRefresh(null);
    window.localStorage.removeItem('refresh');
    api.defaults.headers.common['Authorization'] = '';
    window.location.reload();
  };

  useEffect(() => {
    const lToken = window.localStorage.getItem('token');
    const lRefresh = window.localStorage.getItem('refresh');
    setToken(lToken || '');
    setRefresh(lRefresh || '');
    api.defaults.headers.common['Authorization'] = `Bearer ${lToken}`;
  }, []);

  useEffect(() => {
    if (token && !user) {
      // me()
      //   .then((res) => {
      //     setUser(res.data);
      //   });
    }
  }, [token, user, setUser])

  return (
    <authContext.Provider
      value={{
        token,
        refresh,
        setToken,
        setRefresh,
        user,
        setUser,
        logout,
      }}
    >
      {children}
    </authContext.Provider>
  );
}

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, IconButton } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { get, isEmpty } from "lodash";
import { useState } from "react";
import { get as getApi } from "../../api";
import Add from "./add";
import Delete from "./delete";
import "./style.css";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IUser } from "../../types/user";
import InfoDialog from "./info";
interface ICoach {
  id?: string;
  email?: string;
  name?: string;
  surname?: string;
  role?: string;
  actions?: any;
  calendly?: any;
}

type PaginationParams = {
  page: number;
  pageSize: number;
};

type CoachesQueryKey = [string, PaginationParams];

interface FetchCoachesArgs {
  queryKey: CoachesQueryKey;
}

const List = () => {
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openInvite, setOpenInvite] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [selectedUser, setSelectedUser] = useState<ICoach | null>(null);
  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  const fetchCoaches = async ({ queryKey }: FetchCoachesArgs) => {
    const [_key, { page, pageSize }] = queryKey;
    const response = await getApi(
      `/adminUser/coach/all?page=${page + 1}&take=${pageSize}`
    );
    return response;
  };

  const { data, isLoading, status, error, refetch } = useQuery(
    ["coaches", paginationModel],
    fetchCoaches,
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  // get specific property inside the data object from useQuery
  const coaches = get(data, "data.data", []);

  const removeCoach = (params: GridRenderCellParams<ICoach>) => {
    setSelectedId(get(params, "row.id", ""));
    setOpenDelete(true);
  };

  const closeInfoDialog = () => setOpenInfo(false);

  const closeInviteDialog = () => setOpenInvite(false);

  const closeDeleteDialog = () => {
    setSelectedId("");
    setOpenDelete(false);
  };

  const openInfoDialog = (params: GridRenderCellParams<ICoach>) => {
    setSelectedUser(get(params, "row", null));
    setOpenInfo(true);
  };

  const buildRows = (data: ICoach[]) => {
    const rows: ICoach[] = !isEmpty(data) ? data : [];
    return rows;
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Nome",
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "surname",
      headerName: "Cognome",
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "actions",
      headerName: "Azioni",
      width: 70,
      align: "center",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => removeCoach(params)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
    {
      field: "calendario",
      headerName: "Gestione calendario",
      headerAlign: "center",
      width: 150,
      align: "center",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => openInfoDialog(params)}>
            <VisibilityIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div
      style={{
        overflow: "auto",
        width: "auto",
        display: "flex",
        flexDirection: "column",
        marginTop: 80,
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div>
        {isLoading ? (
          <motion.div
            className="box"
            animate={{
              scale: [1, 2, 2, 1, 1],
              rotate: [0, 0, 180, 180, 0],
              borderRadius: ["25%", "35%", "50%", "50%", "20%"],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              times: [0, 0.15, 0.35, 0.5, 0.7],
              repeat: Infinity,
              repeatDelay: 0.1,
            }}
          />
        ) : (
          <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.5,
              delay: 0.05,
              ease: [0, 0.71, 0.5, 0.8],
            }}
          >
            <div
              style={{
                marginTop: 8,
                marginBottom: 24,
              }}
            >
              <Button
                onClick={() => setOpenInvite(true)}
                variant="contained"
                endIcon={<AddRoundedIcon />}
                sx={[
                  {
                    backgroundColor: "var(--clr-inactive)",
                    borderRadius: 2,
                    color: "var(--clr-white)",
                  },
                  {
                    "&:hover": {
                      backgroundColor: "var(--clr-buildyou-primary)",
                    },
                  },
                ]}
              >
                Aggiungi Coach
              </Button>
            </div>
            <div>
              <DataGrid
                autoHeight
                rows={buildRows(coaches)}
                columns={columns}
                className="hide-focus"
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={(newModel) => {
                  setPaginationModel(newModel);
                }}
                pageSizeOptions={[5, 10, 25, 50]}
                style={{
                  borderRadius: 12,
                  padding: 12,
                  border: "1px solid #FFF",
                  backgroundColor: "var(--clr-white)",
                }}
                sx={{
                  "& .MuiDataGrid-cell:focus-within": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-columnHeader:focus-within": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-iconSeparator": {
                    display: "none",
                  },
                }}
                rowCount={data?.data?.total}
              />
            </div>
          </motion.div>
        )}
      </div>
      {openInvite && (
        <Add
          open={openInvite}
          onClose={closeInviteDialog}
          refreshFn={refetch}
        />
      )}
      {openDelete && (
        <Delete
          id={selectedId}
          open={openDelete}
          onClose={closeDeleteDialog}
          refreshFn={refetch}
        />
      )}
      {selectedUser && (
        <InfoDialog
          user={selectedUser}
          open={openInfo}
          onClose={closeInfoDialog}
          refreshFn={refetch}
        />
      )}
    </div>
  );
};

export default List;

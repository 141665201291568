import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import SearchBar from '../../components/Search/SearchBar';
import SearchResult from '../../components/Search/SearchResult';
import { get as getApi, put as putApi } from '../../api';
import {
  Alert,
  CardActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import { get } from 'lodash';
import CircularColor from '../../components/Spinner/CircularColor';
import { AlertMessage } from '../../types/form';
import { HIDING_TIME_DIALOG, HIDING_TIME_ERROR } from '../../utils/constants';

type AddProps = {
  open: boolean;
  onClose: () => void;
  refreshFn?: Function;
};

type User = {
  avatar: null | string;
  companyAccount: boolean;
  email: string;
  id: string;
  isCoach: boolean;
  name: string;
  surname: string;
};

function Add({ open, onClose, refreshFn }: AddProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedItems, setSelectedItems] = useState<User[]>([]);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [focus, setFocus] = useState<boolean>(true);

  const addCoachesApi = (payload: any) =>
    putApi('adminUser/update/coach', payload);
  const mutation = useMutation(addCoachesApi);

  const fetchCoaches = async () => {
    if (searchTerm) {
      const response = await getApi(`adminUser/search/${searchTerm}`);
      return response;
    }
    return [];
  };

  const handleSubmit = async () => {
    const coachIds = selectedItems.map((chip) => chip.id);

    try {
      await mutation.mutateAsync(
        { isCoach: true, id: coachIds },
        {
          onSuccess: (data) => {
            const status = data?.status ?? 200;

            if (status >= 400) {
              throw new Error('Unable to add Coach');
            } else {
              setAlertMessage({
                severity: 'success',
                message: 'Coach aggiunto con successo',
              });
            }
            if (refreshFn) {
              refreshFn();
            }
          },
          onError: (error) => {
            console.error('An error occurred while submitting:', error);
            setAlertMessage({
              severity: 'error',
              message: "Errore durante l'aggiunta del Coach",
            });
          },
          onSettled: () => {
            setShowAlert(true);

            setTimeout(() => {
              setShowAlert(false);
            }, HIDING_TIME_ERROR);

            setTimeout(() => {
              onClose();
            }, HIDING_TIME_DIALOG);
          },
        }
      );
    } catch (error) {
      console.error('An error occurred while submitting:', error);
    }
  };

  const renderButton = (isLoading: boolean) => {
    const content = isLoading ? <CircularColor /> : <span>Aggiungi</span>;

    const isDisabled =
      !selectedItems?.length || isLoading || !!alertMessage?.message;
    return (
      <Button
        sx={[
          {
            backgroundColor: 'var(--clr-buildyou-primary)',
            borderRadius: 2,
            borderColor: 'var(--clr-buildyou-primary)',
            color: 'var(--clr-white)',
          },
          {
            '&:hover': {
              backgroundColor: 'var(--clr-white)',
              borderColor: 'var(--clr-buildyou-primary)',
              color: 'var(--clr-buildyou-primary)',
            },
          },
        ]}
        fullWidth
        type="submit"
        onClick={handleSubmit}
        disabled={isDisabled}
        variant="outlined"
      >
        {content}
      </Button>
    );
  };

  const {
    data: results,
    isLoading,
    error,
  } = useQuery(['coaches', searchTerm], fetchCoaches, {
    enabled: !!searchTerm,
  });

  const coaches = get(results, 'data', []);

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  const handleChipClick = (result: any) => {
    setSelectedItems((prevSelected) => {
      setFocus(!focus);

      if (prevSelected.find((chip): any => chip.id === result.id)) {
        return prevSelected.filter((chip) => chip.id !== result.id);
      } else {
        setSearchTerm('');
        return [...prevSelected, result];
      }
    });
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Censimento Coach</DialogTitle>

        <DialogContent>
          <div style={{ width: `100%`, minWidth: `450px` }}>
            <div style={{ margin: `12px 4px` }}>
              <div className="form-field">
                <SearchBar
                  onSearch={handleSearch}
                  selectedItems={selectedItems}
                  onItemClick={handleChipClick}
                  searchTerm={searchTerm}
                  focus={focus}
                />
              </div>
            </div>
          </div>
          {isLoading && searchTerm ? (
            <CircularColor />
          ) : (
            <SearchResult
              results={coaches || []}
              onItemClick={handleChipClick}
              selectedItems={selectedItems}
            />
          )}
        </DialogContent>
        <CardActions>
          <Button
            sx={[
              {
                borderRadius: 2,
                borderColor: 'var(--clr-inactive)',
                color: 'var(--clr-inactive)',
              },
              {
                '&:hover': {
                  borderColor: 'var(--clr-inactive)',
                  backgroundColor: 'var(--clr-inactive)',
                  color: 'var(--clr-white)',
                },
              },
            ]}
            fullWidth
            variant="outlined"
            onClick={onClose}
          >
            Annulla
          </Button>
          {renderButton(mutation.isLoading)}
        </CardActions>
        <Snackbar
          open={showAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={4000}
          onClose={() => {
            setShowAlert(false);
            onClose();
          }}
        >
          <Alert severity={alertMessage?.severity}>
            {alertMessage?.message}
          </Alert>
        </Snackbar>
      </Dialog>
    </div>
  );
}

export default Add;

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { Button, IconButton } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { get, isEmpty } from 'lodash';
import { useState } from 'react';
import { get as getApi } from '../../api';
import Add from './add';
import Delete from './delete';
import './style.css';
import { IAds } from '../../types/ads';
import EditDialog from './edit';

type PaginationParams = {
  page: number;
  pageSize: number;
};

type AdsQueryKey = [string, PaginationParams];

interface FetchAdsArgs {
  queryKey: AdsQueryKey;
}

const List = () => {
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [selectedAd, setSelectedAd] = useState<IAds | null>(null);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  const fetchAds = async ({ queryKey }: FetchAdsArgs) => {
    const [_key, { page, pageSize }] = queryKey;
    const response = await getApi(`/ads?page=${page + 1}&take=${pageSize}`);
    return response;
  };

  const { data, isLoading, status, error, refetch } = useQuery(
    ['ads', paginationModel],
    fetchAds
  );

  // get specific property inside the data object from useQuery
  const ads = get(data, 'data.data', []);

  const editAd = (params: GridRenderCellParams<IAds>) => {
    setSelectedAd(get(params, 'row', null));
    setOpenEdit(true);
  };

  const closeEditDialog = () => {
    setSelectedAd(null);
    setOpenEdit(false);
  };

  const removeAd = (params: GridRenderCellParams<IAds>) => {
    setSelectedAd(get(params, 'row', null));
    setOpenDelete(true);
  };

  const closeInviteDialog = () => setOpenCreate(false);

  const closeDeleteDialog = () => {
    setSelectedAd(null);
    setOpenDelete(false);
  };

  const buildRows = (data: IAds[]) => {
    const rows: IAds[] = !isEmpty(data) ? data : [];
    return rows;
  };

  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'url',
      headerName: 'Url',
      width: 250,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'image',
      headerName: 'Image',
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div
          style={{
            height: '100%',
          }}
        >
          {params.value && (
            <img src={params.value} style={{ height: '100%' }} />
          )}
        </div>
      ),
    },
    {
      field: 'actions',
      headerName: 'Azioni',
      headerAlign: 'center',
      width: 150,
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={() => editAd(params)}>
            <VisibilityIcon />
          </IconButton>
          <IconButton onClick={() => removeAd(params)}>
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div
      style={{
        overflow: 'auto',
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 80,
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <div>
        {isLoading ? (
          <motion.div
            className="box"
            animate={{
              scale: [1, 2, 2, 1, 1],
              rotate: [0, 0, 180, 180, 0],
              borderRadius: ['25%', '35%', '50%', '50%', '20%'],
            }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              times: [0, 0.15, 0.35, 0.5, 0.7],
              repeat: Infinity,
              repeatDelay: 0.1,
            }}
          />
        ) : (
          <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.5,
              delay: 0.05,
              ease: [0, 0.71, 0.5, 0.8],
            }}
          >
            <div
              style={{
                marginTop: 8,
                marginBottom: 24,
              }}
            >
              <Button
                onClick={() => setOpenCreate(true)}
                variant="contained"
                endIcon={<AddRoundedIcon />}
                sx={[
                  {
                    backgroundColor: 'var(--clr-inactive)',
                    borderRadius: 2,
                    color: 'var(--clr-white)',
                  },
                  {
                    '&:hover': {
                      backgroundColor: 'var(--clr-buildyou-primary)',
                    },
                  },
                ]}
              >
                Crea nuova Pubblicità
              </Button>
            </div>
            <div>
              <DataGrid
                autoHeight
                rows={buildRows(ads)}
                columns={columns}
                className="hide-focus"
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={(newModel) => {
                  setPaginationModel(newModel);
                }}
                pageSizeOptions={[5, 10, 25, 50]}
                style={{
                  borderRadius: 12,
                  padding: 12,
                  border: '1px solid #FFF',
                  backgroundColor: 'var(--clr-white)',
                }}
                sx={{
                  '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-columnHeader:focus-within': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-iconSeparator': {
                    display: 'none',
                  },
                }}
                rowCount={data?.data?.total}
              />
            </div>
          </motion.div>
        )}
      </div>
      <Add open={openCreate} onClose={closeInviteDialog} refreshFn={refetch} />
      {selectedAd && (
        <EditDialog
          ad={selectedAd}
          open={openEdit}
          onClose={closeEditDialog}
          refreshFn={refetch}
        />
      )}
      {selectedAd && (
        <Delete
          ad={selectedAd}
          open={openDelete}
          onClose={closeDeleteDialog}
          refreshFn={refetch}
        />
      )}
    </div>
  );
};

export default List;

import { AlertColor } from "@mui/material";

export type AlertMessage = {
  severity: AlertColor;
  message: string | undefined;
} | null;

export interface ValidationResponse {
  isValid: boolean;
  error?: string;
}

export enum Feature {
  LOGIN = "LOGIN",
  RESET_PASSWORD = "RESET_PASSWORD",
  REGISTER = "REGISTER",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  CHANGE_COMPANY_PASSWORD = "CHANGE_COMPANY_PASSWORD",
  UNSUSCRIBE = "unsuscribe",
  UNSUSCRIBED = "unsuscribed",
}

export const PasswordQuery = {
  CHANGE_PASSWORD: "adminUser/changePassword",
  CHANGE_COMPANY_PASSWORD: "adminUser/changeCompanyPassword",
};

export interface LoginProps {
  feature: Feature;
}

export interface ChangePasswordPageProps {
  feature: string;
}

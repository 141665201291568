import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Button,
  CardActions,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { get, isEmpty } from 'lodash';
import { useState } from 'react';
import {
  Controller,
  FieldErrors,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { post as postApi } from '../../api';
import { AlertMessage } from '../../types/form';
import { adCreateSchema } from './schema';
import './style.css';

type DeleteModalProps = {
  open: boolean;
  onClose: () => void;
  refreshFn?: Function;
};

interface IAdCreate {
  title: string;
  description: string;
  url: string;
  image?: any;
}

const Add = ({ open, onClose, refreshFn }: DeleteModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const createApi = (payload: IAdCreate) => postApi('/ads', payload);
  const mutation = useMutation(createApi);

  const {
    control,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm<IAdCreate>({
    defaultValues: {
      title: '',
      description: '',
      url: '',
      image: '',
    },
    mode: 'onChange',
    resolver: yupResolver(adCreateSchema),
  });

  const toBase64 = (file: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const onSubmit: SubmitHandler<IAdCreate> = async (data) => {
    const { title, description, url, image } = data;

    const adPayload = {
      title,
      description,
      url,
      image: image ? await toBase64(image) : null,
    };

    try {
      setLoading(true);

      const adResponse = await createApi(adPayload);

      if (adResponse.status >= 400) {
        setAlertMessage({
          severity: 'error',
          message: 'Unable to create ad',
        });
        setShowAlert(true);
        throw new Error('Unable to create ad');
      }

      setAlertMessage({
        severity: 'success',
        message: 'Ad created successfully',
      });
      setShowAlert(true);

      handleClose();
    } catch (error) {
      setAlertMessage({
        severity: 'error',
        message: 'Unable to create ad',
      });
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    if (refreshFn) refreshFn();
    resetField('title');
    resetField('description');
    resetField('url');
    resetField('image');
    onClose();
  };

  const renderErrorSection = (errors: FieldErrors<IAdCreate>, name: string) => {
    const fieldError = get(errors, name, null);
    return !!fieldError ? (
      <div className="form-error-message">
        <div className="form-error-message">
          {get(fieldError, 'message', '')}
        </div>
      </div>
    ) : null;
  };

  const renderInviteError = (responseError: Error | unknown) => {
    const status = get(responseError, 'statusCode', 0);
    const message = get(responseError, 'message', undefined);
    return !isEmpty(message) ? (
      <div className="error-message">
        <div className="error-message">Qualcosa è andato storto.</div>
      </div>
    ) : null;
  };

  const renderButton = (isLoading: boolean, errors: any) => {
    const content = isLoading ? (
      <CircularProgress size={20} />
    ) : (
      <span>Crea</span>
    );

    const isDisabled = isLoading || !isEmpty(errors);
    return (
      <Button
        sx={[
          {
            backgroundColor: 'var(--clr-buildyou-primary)',
            borderRadius: 2,
            borderColor: 'var(--clr-buildyou-primary)',
            color: 'var(--clr-white)',
          },
          {
            '&:hover': {
              backgroundColor: 'var(--clr-white)',
              borderColor: 'var(--clr-buildyou-primary)',
              color: 'var(--clr-buildyou-primary)',
            },
          },
        ]}
        fullWidth
        type="submit"
        onClick={handleSubmit(onSubmit)}
        disabled={isDisabled}
        variant="outlined"
      >
        {content}
      </Button>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Crea nuova pubblicità</DialogTitle>
        <DialogContent>
          <div style={{ width: `100%`, minWidth: `450px` }}>
            <div style={{ margin: `12px 4px` }}>
              <div className="form-field">
                <label>Titolo</label>
                <Controller
                  name="title"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{
                        marginTop: '12px',
                        marginBottom: '12px',
                        lineHeight: '24px',
                        fontWeight: 400,
                        fontSize: '14px',
                      }}
                      size="small"
                      placeholder="Inserisci il titolo"
                      type="text"
                      {...field}
                    />
                  )}
                />
                {renderErrorSection(errors, 'title')}
              </div>

              <div className="form-field">
                <label>Descrizione</label>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{
                        marginTop: '12px',
                        marginBottom: '12px',
                        lineHeight: '24px',
                        fontWeight: 400,
                        fontSize: '14px',
                      }}
                      size="small"
                      placeholder="Inserisci una descrizione"
                      type="text"
                      {...field}
                    />
                  )}
                />
                {renderErrorSection(errors, 'description')}
              </div>

              <div className="form-field">
                <label>Url</label>
                <Controller
                  name="url"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{
                        marginTop: '12px',
                        marginBottom: '12px',
                        lineHeight: '24px',
                        fontWeight: 400,
                        fontSize: '14px',
                      }}
                      size="small"
                      placeholder="Inserisci Url"
                      type="text"
                      {...field}
                    />
                  )}
                />
                {renderErrorSection(errors, 'url')}
              </div>

              <div className="form-field">
                <label>Immagine</label>

                <Controller
                  name="image"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <input
                      type="file"
                      onChange={(e) => {
                        {
                          const file = e.target.files
                            ? e.target.files[0]
                            : null;
                          onChange(file);
                        }
                      }}
                      accept="image/*"
                    />
                  )}
                />
                {renderErrorSection(errors, 'image')}
              </div>
              {renderInviteError(mutation.error)}
            </div>
          </div>
        </DialogContent>
        <CardActions>
          <Button
            sx={[
              {
                borderRadius: 2,
                borderColor: 'var(--clr-inactive)',
                color: 'var(--clr-inactive)',
              },
              {
                '&:hover': {
                  borderColor: 'var(--clr-inactive)',
                  backgroundColor: 'var(--clr-inactive)',
                  color: 'var(--clr-white)',
                },
              },
            ]}
            fullWidth
            variant="outlined"
            onClick={handleClose}
          >
            Annulla
          </Button>
          {renderButton(mutation.isLoading, errors)}
        </CardActions>
        <Snackbar
          open={showAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={4000}
          onClose={() => setShowAlert(false)}
        >
          <Alert severity={alertMessage?.severity}>
            {alertMessage?.message}
          </Alert>
        </Snackbar>
      </Dialog>
    </form>
  );
};

export default Add;

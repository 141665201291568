import { BrowserRouter } from 'react-router-dom';
// import { Button } from '@mui/material';
import { AuthProvider } from './context/auth';
import { AxiosComponent } from './models/api';
import RoutesHOC from './routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// const ErrorFallback = ({ error, resetErrorBoundary }) => {
//   return (
//     <div role="alert" style={{ marginTop: '60px', marginLeft: '20px' }}>
//       <p>Qualcosa è andato storto</p>
//       <Button onClick={resetErrorBoundary} variant="outlined">
//         Torna indietro
//       </Button>
//       <small>
//         <pre>{error.message}</pre>
//         <pre>{error.stack}</pre>
//       </small>
//     </div>
//   );
// };
function DateAdapterHOC({ children }) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {children}
    </LocalizationProvider>
  );
}

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider>
            <DateAdapterHOC>
              {/* <AxiosComponent /> */}
              <RoutesHOC />
            </DateAdapterHOC>
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;

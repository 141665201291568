import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

export default function CircularColor() {
  return (
    <Stack
      sx={{ color: '#ffa41b' }}
      spacing={2}
      direction="row"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress color="inherit" />
    </Stack>
  );
}

import { List, ListItem, Box } from "@mui/material";

type SearchResultProps = {
  results: User[];
  selectedItem: User[];
  onItemClick: (user: User) => void;
};

type User = {
  avatar: null | string;
  companyAccount: boolean;
  email: string;
  id: string;
  isCoach: boolean;
  name: string;
  surname: string;
  specialization?: any;
};

const SearchResultList = ({
  results,
  onItemClick,
  selectedItem,
}: SearchResultProps) => {
  return (
    <Box sx={{ maxHeight: 250, overflowY: "auto" }}>
      <List component="nav">
        {results?.map((result: User) => (
          <ListItem
            key={result.id}
            onClick={() => onItemClick(result)}
            sx={{
              backgroundColor:
                selectedItem[0]?.id === result.id
                  ? "action.selected"
                  : "transparent",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "action.hover",
              },
            }}
          >
            {`${result.name} ${result.surname}`}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default SearchResultList;

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Button,
  CardActions,
  CircularProgress,
  Snackbar,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { get, isEmpty } from 'lodash';
import { useState } from 'react';
import {
  Controller,
  FieldErrors,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { post as postApi } from '../../api';
import { AlertMessage, Feature, PasswordQuery } from '../../types/form';
import { resetPasswordSchema as schema } from '../../features/password/schema';
import '../../features/password/style.css';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface IChangePassword {
  password: string;
  confirmPassword?: string;
  email: string;
  code: string;
}

interface ChangePasswordProps {
  title?: string;
  subTitle?: string;
  feature: string;
}

const ChangePassword = ({ title, subTitle, feature }: ChangePasswordProps) => {
  const navigate = useNavigate();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const codeParam = params.get('code') || '';
  const emailParam = params.get('email') || '';

  const [loading, setLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const query =
    feature === Feature.CHANGE_COMPANY_PASSWORD
      ? PasswordQuery.CHANGE_COMPANY_PASSWORD
      : PasswordQuery.CHANGE_PASSWORD;

  const resetApi = (payload: IChangePassword) => postApi(query, payload);
  const mutation = useMutation(resetApi);

  const {
    control,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm<IChangePassword>({
    defaultValues: {
      password: '',
      confirmPassword: '',
      email: emailParam,
      code: codeParam,
    },
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const onSubmit: SubmitHandler<IChangePassword> = async (data) => {
    const payload = {
      password: data.password,
      email: data.email,
      code: data.code,
    };
    mutation.mutate(payload, {
      onSuccess: (data) => {
        const status: number = get(data, 'response.status', 0);
        if (status >= 400) {
          setAlertMessage({
            severity: 'error',
            message: 'Errore',
          });
          setShowAlert(true);
          throw new Error('Unable to invite');
        }
        setAlertMessage({
          severity: 'success',
          message: 'Password cambiata con successo',
        });
        setShowAlert(true);
        resetFields();
      },
    });
  };

  const resetFields = () => {
    resetField('email');
    resetField('password');
    resetField('confirmPassword');
    resetField('code');
  };

  const renderErrorSection = (
    errors: FieldErrors<IChangePassword>,
    name: string
  ) => {
    const fieldError = get(errors, name, null);
    return !!fieldError ? (
      <div className="form-error-message">
        <div className="form-error-message">
          {get(fieldError, 'message', '')}
        </div>
      </div>
    ) : null;
  };

  const renderResetError = (responseError: Error | unknown) => {
    const status = get(responseError, 'statusCode', 0);
    const message = get(responseError, 'message', undefined);
    return !isEmpty(message) ? (
      <div className="error-message">
        <div className="error-message">{message}</div>
      </div>
    ) : null;
  };

  const renderButton = (isLoading: boolean, errors: any) => {
    const content = isLoading ? (
      <CircularProgress size={20} />
    ) : (
      <span>Conferma</span>
    );

    const isDisabled = isLoading || !isEmpty(errors);
    return (
      <Button
        sx={[
          {
            backgroundColor: 'var(--clr-buildyou-primary)',
            borderRadius: 2,
            borderColor: 'var(--clr-buildyou-primary)',
            color: 'var(--clr-white)',
          },
          {
            '&:hover': {
              backgroundColor: 'var(--clr-white)',
              borderColor: 'var(--clr-buildyou-primary)',
              color: 'var(--clr-buildyou-primary)',
            },
          },
        ]}
        fullWidth
        type="submit"
        onClick={handleSubmit(onSubmit)}
        disabled={isDisabled}
        variant="outlined"
      >
        {content}
      </Button>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>{title}</h2>
      <p>{subTitle}</p>
      <div
        style={{
          width: `100%`,
          minWidth: `450px`,
        }}
      >
        <Controller
          name="email"
          control={control}
          render={({ field }) => <input type="hidden" {...field} />}
        />

        <div className="form-field">
          <label>Password</label>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                sx={{
                  marginTop: '12px',
                  marginBottom: '12px',
                  lineHeight: '24px',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
                size="small"
                placeholder="Inserisci nuova password"
                type="password"
                {...field}
              />
            )}
          />
          {renderErrorSection(errors, 'password')}
        </div>

        <div className="form-field">
          <label>Conferma password</label>
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <TextField
                sx={{
                  marginTop: '12px',
                  marginBottom: '12px',
                  lineHeight: '24px',
                  fontWeight: 400,
                  fontSize: '14px',
                }}
                size="small"
                placeholder="Conferma password"
                type="password"
                {...field}
              />
            )}
          />
          {renderErrorSection(errors, 'confirmPassword')}
        </div>

        <Controller
          name="code"
          control={control}
          render={({ field }) => <input type="hidden" {...field} />}
        />

        {renderResetError(mutation.error)}
      </div>
      <CardActions>
        <Button
          sx={[
            {
              borderRadius: 2,
              borderColor: 'var(--clr-inactive)',
              color: 'var(--clr-inactive)',
            },
            {
              '&:hover': {
                borderColor: 'var(--clr-inactive)',
                backgroundColor: 'var(--clr-inactive)',
                color: 'var(--clr-white)',
              },
            },
          ]}
          fullWidth
          variant="outlined"
          onClick={() => navigate('/login')}
        >
          Indietro
        </Button>
        {renderButton(mutation.isLoading, errors)}
      </CardActions>
      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={4000}
        onClose={() => {
          setShowAlert(false);
          if (alertMessage?.severity === 'success') navigate('/login');
        }}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </form>
  );
};

export default ChangePassword;

import * as yup from 'yup';

export const companyCreateSchema = yup.object().shape({
  ragioneSociale: yup.string().required('Il campo Ragione Sociale è richiesto'),
  partitaIva: yup.string().required('Il campo Partita Iva è richiesto'),
  email: yup
    .string()
    .email('Inserisci un formato email valido')
    .required('Il campo Email è richiesto'),
});

export const paymentCreateSchema = yup.object().shape({
  importo: yup
    .number()
    .transform((value, originalValue) => {
      const strValue = String(originalValue).trim();
      return strValue === '' ? null : Number(strValue.replace(',', '.'));
    })
    .required('Il campo Importo è richiesto')
    .min(0, 'Il campo Importo deve essere maggiore o uguale a zero')
    .test(
      'decimal',
      'Non possono essere presenti più di 2 decimali',
      (number) => {
        return /^\d+([.,]\d{1,2})?$/.test(number.toString());
      }
    )
    .typeError('Deve essere un numero'),
  crediti: yup
    .number()
    .integer('Deve essere un numero intero')
    .required('Deve essere un numero intero')
    .typeError('Deve essere un numero intero'),
});

export const packageCreateSchema = yup.object().shape({
  importo: yup
    .number()
    .transform((value, originalValue) => {
      const strValue = String(originalValue).trim();
      return strValue === '' ? null : Number(strValue.replace(',', '.'));
    })
    .required('Il campo Importo è richiesto')
    .min(0, 'Il campo Importo deve essere maggiore o uguale a zero')
    .test(
      'decimal',
      'Non possono essere presenti più di 2 decimali',
      (number) => {
        return /^\d+([.,]\d{1,2})?$/.test(number.toString());
      }
    )
    .typeError('Deve essere un numero'),
  basicPackage: yup
    .number()
    .integer('Deve essere un numero intero')
    .required('Deve essere un numero intero')
    .typeError('Deve essere un numero intero'),
  premiumPackage: yup
    .number()
    .integer('Deve essere un numero intero')
    .required('Deve essere un numero intero')
    .typeError('Deve essere un numero intero'),
});

export const paymentEditSchema = yup.object().shape({
  creditiTotali: yup.string().required('Il campo Crediti Totali è richiesto'),
  importo: yup.number().required('Il campo Importo è richiesto'),
});


export const certifiedCreateSchema = yup.object().shape({
  importo: yup
    .number()
    .transform((value, originalValue) => {
      const strValue = String(originalValue).trim();
      return strValue === '' ? null : Number(strValue.replace(',', '.'));
    })
    .required('Il campo Importo è richiesto')
    .min(0, 'Il campo Importo deve essere maggiore o uguale a zero')
    .test(
      'decimal',
      'Non possono essere presenti più di 2 decimali',
      (number) => {
        return /^\d+([.,]\d{1,2})?$/.test(number.toString());
      }
    )
    .typeError('Deve essere un numero'),
  chat: yup
    .number()
    .transform((value, originalValue) => {
      const strValue = String(originalValue).trim();
      return strValue === '' ? null : Number(strValue);
    })
    .required('Il campo Chat è richiesto')
    .min(0, 'Il campo Chat deve essere maggiore o uguale a zero')
    .typeError('Deve essere un numero'),
  call: yup
    .number()
    .transform((value, originalValue) => {
      const strValue = String(originalValue).trim();
      return strValue === '' ? null : Number(strValue);
    })
    .required('Il campo Call è richiesto')
    .min(0, 'Il campo Call deve essere maggiore o uguale a zero')
    .typeError('Deve essere un numero'),
});

export const certifiedEditSchema = yup.object().shape({
  importo: yup.number().required('Il campo coach è richiesto'),
});
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Button,
  CardActions,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { get, isEmpty } from 'lodash';
import { useState } from 'react';
import {
  Controller,
  FieldErrors,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { put as putApi } from '../../api';
import { AlertMessage } from '../../types/form';
import { adEditSchema } from './schema';
import './style.css';
import { IAds } from '../../types/ads';
import { baseURL } from '../../models/api';

type EditDialogProps = {
  ad: IAds;
  open: boolean;
  onClose: () => void;
  refreshFn?: Function;
};

interface IAdEdit {
  title: string;
  description: string;
  url: string;
  image?: any;
}

const EditDialog = ({ ad, open, onClose, refreshFn }: EditDialogProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const editApi = (payload: IAdEdit) => putApi(`/ads/${ad.id}`, payload);
  const mutation = useMutation(editApi);

  const {
    control,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm<IAdEdit>({
    defaultValues: {
      title: ad.title,
      description: ad.description,
      url: ad.url,
      image: '',
    },
    mode: 'onChange',
    resolver: yupResolver(adEditSchema),
  });

  const toBase64 = (file: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });

  const onSubmit: SubmitHandler<IAdEdit> = async (data) => {
    const { title, description, url, image } = data;

    const adPayload: IAdEdit = {
      title,
      description,
      url,
    };
    // If the image is an empty string, do nothing
    if (image === '') {
    }
    // If the image is undefined, it means we need to remove it
    else if (image === undefined) {
      adPayload.image = null;
    }
    // If the image is a File object, convert it to Base64
    else if (typeof image === 'object') {
      adPayload.image = await toBase64(image);
    }

    try {
      setLoading(true);

      const adResponse = await editApi(adPayload);

      if (adResponse.status >= 400) {
        setAlertMessage({
          severity: 'error',
          message: 'Unable to create ad',
        });
        setShowAlert(true);
        throw new Error('Unable to create ad');
      }

      setAlertMessage({
        severity: 'success',
        message: 'Ad created successfully',
      });
      setShowAlert(true);

      if (refreshFn) refreshFn();

      handleClose();
    } catch (error) {
      setAlertMessage({
        severity: 'error',
        message: 'Unable to create ad',
      });
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    resetField('title');
    resetField('description');
    resetField('url');
    resetField('image');
    onClose();
  };

  const renderErrorSection = (errors: FieldErrors<IAdEdit>, name: string) => {
    const fieldError = get(errors, name, null);
    return !!fieldError ? (
      <div className="form-error-message">
        <div className="form-error-message">
          {get(fieldError, 'message', '')}
        </div>
      </div>
    ) : null;
  };

  const renderInviteError = (responseError: Error | unknown) => {
    const status = get(responseError, 'statusCode', 0);
    const message = get(responseError, 'message', undefined);
    return !isEmpty(message) ? (
      <div className="error-message">
        <div className="error-message">Qualcosa è andato storto.</div>
      </div>
    ) : null;
  };

  const renderButton = (isLoading: boolean, errors: any) => {
    const content = isLoading ? (
      <CircularProgress size={20} />
    ) : (
      <span>Salva</span>
    );

    const isDisabled = isLoading || !isEmpty(errors);
    return (
      <Button
        sx={[
          {
            backgroundColor: 'var(--clr-buildyou-primary)',
            borderRadius: 2,
            borderColor: 'var(--clr-buildyou-primary)',
            color: 'var(--clr-white)',
          },
          {
            '&:hover': {
              backgroundColor: 'var(--clr-white)',
              borderColor: 'var(--clr-buildyou-primary)',
              color: 'var(--clr-buildyou-primary)',
            },
          },
        ]}
        fullWidth
        type="submit"
        onClick={handleSubmit(onSubmit)}
        disabled={isDisabled}
        variant="outlined"
      >
        {content}
      </Button>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Aggiorna pubblicità</DialogTitle>
        <DialogContent>
          <div style={{ width: `100%`, minWidth: `450px` }}>
            <div style={{ margin: `12px 4px` }}>
              <div className="form-field">
                <img src={ad.image} style={{ marginBottom: '12px', maxWidth: '450px' }} />
                <label>Immagine</label>
                <Controller
                  name="image"
                  control={control}
                  render={({ field: { onChange } }) => (
                    <input
                      type="file"
                      onChange={(e) => {
                        {
                          const file =
                            e.target.files && e.target.files[0]
                              ? e.target.files[0]
                              : undefined;
                          onChange(file);
                        }
                      }}
                      accept="image/*"
                    />
                  )}
                />
                {renderErrorSection(errors, 'image')}
              </div>
              <div className="form-field">
                <label>Titolo</label>
                <Controller
                  name="title"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{
                        marginTop: '12px',
                        marginBottom: '12px',
                        lineHeight: '24px',
                        fontWeight: 400,
                        fontSize: '14px',
                      }}
                      size="small"
                      placeholder="Inserisci il titolo"
                      type="text"
                      {...field}
                    />
                  )}
                />
                {renderErrorSection(errors, 'title')}
              </div>

              <div className="form-field">
                <label>Descrizione</label>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{
                        marginTop: '12px',
                        marginBottom: '12px',
                        lineHeight: '24px',
                        fontWeight: 400,
                        fontSize: '14px',
                      }}
                      size="small"
                      placeholder="Inserisci una descrizione"
                      type="text"
                      {...field}
                    />
                  )}
                />
                {renderErrorSection(errors, 'description')}
              </div>

              <div className="form-field">
                <label>Url</label>
                <Controller
                  name="url"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{
                        marginTop: '12px',
                        marginBottom: '12px',
                        lineHeight: '24px',
                        fontWeight: 400,
                        fontSize: '14px',
                      }}
                      size="small"
                      placeholder="Inserisci Url"
                      type="text"
                      {...field}
                    />
                  )}
                />
                {renderErrorSection(errors, 'url')}
              </div>

              {renderInviteError(mutation.error)}
            </div>
          </div>
        </DialogContent>
        <CardActions>
          <Button
            sx={[
              {
                borderRadius: 2,
                borderColor: 'var(--clr-inactive)',
                color: 'var(--clr-inactive)',
              },
              {
                '&:hover': {
                  borderColor: 'var(--clr-inactive)',
                  backgroundColor: 'var(--clr-inactive)',
                  color: 'var(--clr-white)',
                },
              },
            ]}
            fullWidth
            variant="outlined"
            onClick={handleClose}
          >
            Annulla
          </Button>
          {renderButton(mutation.isLoading, errors)}
        </CardActions>
        <Snackbar
          open={showAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={4000}
          onClose={() => setShowAlert(false)}
        >
          <Alert severity={alertMessage?.severity}>
            {alertMessage?.message}
          </Alert>
        </Snackbar>
      </Dialog>
    </form>
  );
};

export default EditDialog;

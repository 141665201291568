import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ChangePassword from '../../components/Password/ChangePassword';
import { ChangePasswordPageProps, Feature } from '../../types/form';

const ChangePasswordPage = ({ feature }: ChangePasswordPageProps) => {
  return (
    <>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        gap={2}
        //sx={{ padding: '40px' }}
        style={{ height: '100vh', width: '100%' }}
      >
        <Grid
          item
          xs={12}
          // marginTop="20vh"
        >
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              {feature === Feature.CHANGE_PASSWORD ? (
                <ChangePassword title="Reset password" feature={feature} />
              ) : (
                <ChangePassword
                  title="Reset password - Azienda"
                  feature={feature}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ChangePasswordPage;

import { Chip } from '@mui/material';

type SearchResultProps = {
  results: User[];
  selectedItems: User[];
  onItemClick: (user: User) => void;
};

type User = {
  avatar: null | string;
  companyAccount: boolean;
  email: string;
  id: string;
  isCoach: boolean;
  name: string;
  surname: string;
};

const SearchResult = ({
  results,
  onItemClick,
  selectedItems,
}: SearchResultProps) => {
  return (
    <div>
      {Array.isArray(results) &&
        results?.map((result: User) => (
          <Chip
            key={result.id}
            label={`${result?.name} ${result?.surname}`}
            clickable
            onClick={() => onItemClick(result)}
            color={
              selectedItems.find((chip: User) => chip.id === result.id)
                ? 'primary'
                : 'default'
            }
            style={{
              backgroundColor: selectedItems.find(
                (chip: any) => chip.id === result.id
              )
                ? 'var(--clr-buildyou-primary)'
                : 'var(--clr-inactive-background-mui)',
            }}
          />
        ))}
    </div>
  );
};

export default SearchResult;

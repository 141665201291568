import { Suspense, lazy, useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Layout from "../components/Layout";
import Loading from "../components/Loading";
import { authContext } from "../context/auth";
import { getLocalTokens } from "../utils/auth";
import ChangePasswordPage from "../features/password";
import { Feature } from "../types/form";
import AdsPage from "../features/ads";
import CompanyPage from "../features/company";
import CoachPage from "../features/coach";
import Unsuscribed from "../components/Password/Unsuscribed";
import ChallengePage from "../features/challenge";

const Login = lazy(() => import("../features/login"));
const Home = lazy(() => import("../features/home"));
const User = lazy(() => import("../features/users"));
const Admin = lazy(() => import("../features/admin"));

export const AUTH_ROUTES = [
  {
    id: "admin",
    path: "/admin",
    element: <Admin />,
  },
  {
    id: "user",
    path: "/user",
    element: <User />,
  },
  {
    id: "ads",
    path: "/ads",
    element: <AdsPage />,
  },
  {
    id: "company",
    path: "/company",
    element: <CompanyPage />,
  },
  {
    id: 'coach',
    path: '/coach',
    element: <CoachPage />,
  },
  {
    id: "authenticated",
    path: "/*",
    element: <Navigate to="/admin" replace />,
  },
  {
    id: "challenge",
    path: "/challenge",
    element: <ChallengePage />,
  },
  {
    id: "authenticated",
    path: "/*",
    element: <Navigate to="/" replace />,
  },
];

export const UNAUTH_ROUTES = [
  {
    id: "register",
    path: "/register",

    element: <Login feature={Feature.REGISTER} />,
  },
  {
    id: "login",
    path: "/login",
    element: <Login feature={Feature.LOGIN} />,
  },
  {
    id: "reset",
    path: "/reset-password",
    element: <Login feature={Feature.RESET_PASSWORD} />,
  },
  {
    id: "change",
    path: "/change-password",
    element: <ChangePasswordPage feature={Feature.CHANGE_PASSWORD} />,
  },
  {
    id: "change",
    path: "/company-change-password",
    element: <ChangePasswordPage feature={Feature.CHANGE_COMPANY_PASSWORD} />,
  },
  {
    id: "unauthenticated",
    path: "/*",
    element: <Navigate to="/login" replace />,
  },
  {
    id: "unsuscribe",
    path: "/unsuscribe",
    element: <Login feature={Feature.UNSUSCRIBE} />,
  },
  {
    id: "unsuscribed",
    path: "/unsuscribed",
    element: <Unsuscribed />,
  },
];

const RoutesHOC = () => {
  const { token: authenticated, user } = useContext(authContext);
  const { token: accessToken, refresh } = getLocalTokens();

  if (authenticated || accessToken) {
    return (
      <Layout>
        <Suspense fallback={<Loading />}>
          <Routes>
            {AUTH_ROUTES.map((r) => (
              <Route key={r.id} path={r.path} element={r.element} />
            ))}
          </Routes>
        </Suspense>
      </Layout>
    );
  }
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Suspense fallback={<Loading />}>
        <Routes>
          {UNAUTH_ROUTES.map((r) => (
            <Route key={r.id} path={r.path} element={r.element} />
          ))}
        </Routes>
      </Suspense>
    </div>
  );
};

export default RoutesHOC;

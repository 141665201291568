import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  CardActions,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { get, isEmpty } from "lodash";
import { useState } from "react";
import {
  Controller,
  FieldErrors,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { post as postApi } from "../../api";
import { AlertMessage } from "../../types/form";
import { calendly } from "./schema";
import "./style.css";
import { InputAdornment } from "@mui/material";
import React from "react";

interface ICoach {
  id?: string;
  email?: string;
  name?: string;
  surname?: string;
  role?: string;
  actions?: any;
  calendly?: any;
}

/*interface ICoachPayload {
  id: string;
  calendly: string;
}*/

type EditDialogProps = {
  user: ICoach;
  open: boolean;
  onClose: () => void;
  refreshFn?: Function;
};

const Info = ({ user, open, onClose, refreshFn }: EditDialogProps) => {
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const createCalendlyApi = (payload: ICoach) =>
    postApi(`/user/update/calendly`, payload);
  const mutation = useMutation(createCalendlyApi);

  const {
    control,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm<ICoach>({
    defaultValues: {
      id: user.id,
      calendly: "",
    },
    mode: "onChange",
    resolver: yupResolver(calendly),
  });
  const resetFields = () => {
    resetField("calendly");
  };
  const onSubmit: SubmitHandler<ICoach> = async (data) => {
    const payload = {
      id: data.id,
      calendly: data.calendly,
    };
    mutation.mutate(payload, {
      onSuccess: (data) => {
        const status: number = get(data, "response.status", 0);
        if (status >= 400) {
          setAlertMessage({
            severity: "error",
            message: "Errore",
          });
          setShowAlert(true);
          throw new Error("Unable to update");
        }
        setAlertMessage({
          severity: "success",
          message: "Link al calendario salvato con successo.",
        });
        setShowAlert(true);
        resetFields();
        if (refreshFn) {
          refreshFn(); // Call refresh function if available
        }
        onClose();
      },
    });
  };

  const handleClose = () => {
    onClose();
  };

  const renderErrorSection = (errors: FieldErrors<ICoach>, name: string) => {
    const fieldError = get(errors, name, null);
    return !!fieldError ? (
      <div className="form-error-message">
        <div className="form-error-message">
          {get(fieldError, "message", "")}
        </div>
      </div>
    ) : null;
  };

  const renderCreateError = (responseError: Error | unknown) => {
    const status = get(responseError, "statusCode", 0);
    const message = get(responseError, "message", undefined);
    return !isEmpty(message) ? (
      <div className="error-message">
        <div className="error-message">Qualcosa è andato storto.</div>
      </div>
    ) : null;
  };

  const renderButton = (isLoading: boolean, errors: any) => {
    const content = isLoading ? (
      <CircularProgress size={20} />
    ) : (
      <span>Salva</span>
    );

    const isDisabled = isLoading || !isEmpty(errors);
    return (
      <Button
        sx={[
          {
            backgroundColor: "var(--clr-buildyou-primary)",
            borderRadius: 2,
            borderColor: "var(--clr-buildyou-primary)",
            color: "var(--clr-white)",
          },
          {
            "&:hover": {
              backgroundColor: "var(--clr-white)",
              borderColor: "var(--clr-buildyou-primary)",
              color: "var(--clr-buildyou-primary)",
            },
          },
        ]}
        fullWidth
        type="submit"
        onClick={handleSubmit(onSubmit)}
        disabled={isDisabled}
        variant="outlined"
      >
        {content}
      </Button>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Gestione calendario</DialogTitle>
        <DialogContent>
          <div style={{ width: `100%`, minWidth: `450px` }}>
            <div style={{ margin: `12px 4px` }}>
              <div className="form-field">
                <label>Nome</label>
                <TextField
                  sx={{
                    marginTop: "12px",
                    marginBottom: "12px",
                    lineHeight: "24px",
                    fontWeight: 400,
                    fontSize: "14px",
                  }}
                  size="small"
                  value={user?.name || ""}
                  disabled
                  type="text"
                />
              </div>
              <div className="form-field">
                <label>Cognome</label>
                <TextField
                  sx={{
                    marginTop: "12px",
                    marginBottom: "12px",
                    lineHeight: "24px",
                    fontWeight: 400,
                    fontSize: "14px",
                  }}
                  size="small"
                  value={user?.surname || ""}
                  disabled
                  type="text"
                />
              </div>

              <div className="form-field">
                <label>Email</label>
                <TextField
                  sx={{
                    marginTop: "12px",
                    marginBottom: "12px",
                    lineHeight: "24px",
                    fontWeight: 400,
                    fontSize: "14px",
                  }}
                  size="small"
                  value={user?.email || ""}
                  disabled
                  type="text"
                />
              </div>

              <div className="form-field">
                <label>Link al calendario</label>
                <Controller
                  name="calendly"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{
                        marginTop: "12px",
                        marginBottom: "12px",
                        lineHeight: "24px",
                        fontWeight: 400,
                        fontSize: "14px",
                      }}
                      size="small"
                      placeholder="Inserisci il link al calendario"
                      type="text"
                      defaultValue={user.calendly} // Imposta il valore predefinito
                      onChange={field.onChange}
                    />
                  )}
                />
                {renderErrorSection(errors, "calendly")}{" "}
              </div>

              {renderCreateError(mutation.error)}
            </div>
          </div>
        </DialogContent>
        <CardActions>
          <Button
            sx={[
              {
                borderRadius: 2,
                borderColor: "var(--clr-inactive)",
                color: "var(--clr-inactive)",
              },
              {
                "&:hover": {
                  borderColor: "var(--clr-inactive)",
                  backgroundColor: "var(--clr-inactive)",
                  color: "var(--clr-white)",
                },
              },
            ]}
            fullWidth
            variant="outlined"
            onClick={handleClose}
          >
            Annulla
          </Button>
          {renderButton(mutation.isLoading, errors)}
        </CardActions>
        <Snackbar
          open={showAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={4000}
          onClose={() => setShowAlert(false)}
        >
          <Alert severity={alertMessage?.severity}>
            {alertMessage?.message}
          </Alert>
        </Snackbar>
      </Dialog>
    </form>
  );
};
export default Info;

import { yupResolver } from '@hookform/resolvers/yup';
import {
  Alert,
  Button,
  CardActions,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { get, isEmpty } from 'lodash';
import { useState } from 'react';
import {
  Controller,
  FieldErrors,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { post as postApi } from '../../api';
import { AlertMessage } from '../../types/form';
import { companyCreateSchema } from './schema';
import './style.css';

type CreateModalProps = {
  open: boolean;
  onClose: () => void;
  refreshFn?: Function;
};

interface ICompanyCreate {
  ragioneSociale: string;
  partitaIva: string;
  email: string;
}

const Add = ({ open, onClose, refreshFn }: CreateModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const createApi = (payload: ICompanyCreate) =>
    postApi('/adminUser/company/create', payload);
  const mutation = useMutation(createApi);

  const {
    control,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm<ICompanyCreate>({
    defaultValues: {
      ragioneSociale: '',
      partitaIva: '',
      email: '',
    },
    mode: 'onChange',
    resolver: yupResolver(companyCreateSchema),
  });

  const onSubmit: SubmitHandler<ICompanyCreate> = async (data) => {
    const { ragioneSociale, partitaIva, email } = data;

    const companyPayload = {
      ragioneSociale,
      partitaIva,
      email,
    };

    try {
      setLoading(true);

      const companyResponse = await createApi(companyPayload);

      if (companyResponse.status >= 400) {
        setAlertMessage({
          severity: 'error',
          message: 'Unable to create Company',
        });
        setShowAlert(true);
        throw new Error('Unable to create Company');
      }

      setAlertMessage({
        severity: 'success',
        message: 'Company created successfully',
      });
      setShowAlert(true);

      if (refreshFn) refreshFn();

      handleClose();
    } catch (error) {
      setAlertMessage({
        severity: 'error',
        message: 'Unable to create Company',
      });
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    resetField('ragioneSociale');
    resetField('partitaIva');
    resetField('email');
    onClose();
  };

  const renderErrorSection = (
    errors: FieldErrors<ICompanyCreate>,
    name: string
  ) => {
    const fieldError = get(errors, name, null);
    return !!fieldError ? (
      <div className="form-error-message">
        <div className="form-error-message">
          {get(fieldError, 'message', '')}
        </div>
      </div>
    ) : null;
  };

  const renderCreateError = (responseError: Error | unknown) => {
    const status = get(responseError, 'statusCode', 0);
    const message = get(responseError, 'message', undefined);
    return !isEmpty(message) ? (
      <div className="error-message">
        <div className="error-message">Qualcosa è andato storto.</div>
      </div>
    ) : null;
  };

  const renderButton = (isLoading: boolean, errors: any) => {
    const content = isLoading ? (
      <CircularProgress size={20} />
    ) : (
      <span>Crea</span>
    );

    const isDisabled = isLoading || !isEmpty(errors);
    return (
      <Button
        sx={[
          {
            backgroundColor: 'var(--clr-buildyou-primary)',
            borderRadius: 2,
            borderColor: 'var(--clr-buildyou-primary)',
            color: 'var(--clr-white)',
          },
          {
            '&:hover': {
              backgroundColor: 'var(--clr-white)',
              borderColor: 'var(--clr-buildyou-primary)',
              color: 'var(--clr-buildyou-primary)',
            },
          },
        ]}
        fullWidth
        type="submit"
        onClick={handleSubmit(onSubmit)}
        disabled={isDisabled}
        variant="outlined"
      >
        {content}
      </Button>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Crea Azienda</DialogTitle>
        <DialogContent>
          <div style={{ width: `100%`, minWidth: `450px` }}>
            <div style={{ margin: `12px 4px` }}>
              <div className="form-field">
                <label>Ragione Sociale</label>
                <Controller
                  name="ragioneSociale"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{
                        marginTop: '12px',
                        marginBottom: '12px',
                        lineHeight: '24px',
                        fontWeight: 400,
                        fontSize: '14px',
                      }}
                      size="small"
                      placeholder="Inserisci Ragione Sociale"
                      type="text"
                      {...field}
                    />
                  )}
                />
                {renderErrorSection(errors, 'ragioneSociale')}
              </div>

              <div className="form-field">
                <label>Partita Iva</label>
                <Controller
                  name="partitaIva"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{
                        marginTop: '12px',
                        marginBottom: '12px',
                        lineHeight: '24px',
                        fontWeight: 400,
                        fontSize: '14px',
                      }}
                      size="small"
                      placeholder="Inserisci una Partita Iva"
                      type="text"
                      {...field}
                    />
                  )}
                />
                {renderErrorSection(errors, 'partitaIva')}
              </div>

              <div className="form-field">
                <label>Email</label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{
                        marginTop: '12px',
                        marginBottom: '12px',
                        lineHeight: '24px',
                        fontWeight: 400,
                        fontSize: '14px',
                      }}
                      size="small"
                      placeholder="Inserisci Email"
                      type="text"
                      {...field}
                    />
                  )}
                />
                {renderErrorSection(errors, 'email')}
              </div>

              {renderCreateError(mutation.error)}
            </div>
          </div>
        </DialogContent>
        <CardActions>
          <Button
            sx={[
              {
                borderRadius: 2,
                borderColor: 'var(--clr-inactive)',
                color: 'var(--clr-inactive)',
              },
              {
                '&:hover': {
                  borderColor: 'var(--clr-inactive)',
                  backgroundColor: 'var(--clr-inactive)',
                  color: 'var(--clr-white)',
                },
              },
            ]}
            fullWidth
            variant="outlined"
            onClick={handleClose}
          >
            Annulla
          </Button>
          {renderButton(mutation.isLoading, errors)}
        </CardActions>
        <Snackbar
          open={showAlert}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={4000}
          onClose={() => setShowAlert(false)}
        >
          <Alert severity={alertMessage?.severity}>
            {alertMessage?.message}
          </Alert>
        </Snackbar>
      </Dialog>
    </form>
  );
};

export default Add;

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  CardActions,
  CircularProgress,
  Snackbar,
  TextField,
} from "@mui/material";

import React, { useState } from "react";
import { AlertMessage } from "../../types/form";
import "../../features/password/style.css";
import { useNavigate } from "react-router-dom";

const Unsuscribed = () => {
  return (
    <div
      style={{
        width: `100%`,
        minWidth: `450px`,
        display: "flex",
        justifyContent: `center`,
        flexDirection: "column",
        alignItems: "center",
        height: `100%`,
      }}
    >
      <h2>Account cancellato con successo.</h2>
      <br></br>
      <p>
        Grazie per essere stato con noi fino ad oggi, speriamo di rivederti
        presto.
      </p>
    </div>
  );
};

export default Unsuscribed;

import * as yup from 'yup';

export const adCreateSchema = yup.object().shape({
  title: yup.string().required('Il campo Titolo è richiesto'),
  description: yup.string().required('Il campo Descrizione è richiesto'),
  url: yup
    .string()
    .url('Formato URL non valido')
    .required('Il campo URL è richiesto'),
  image: yup.mixed().defined('Il campo Immagine è richiesto'),
});

export const adEditSchema = yup.object().shape({
  title: yup.string().required('Il campo Titolo è richiesto'),
  description: yup.string().required('Il campo Descrizione è richiesto'),
  url: yup
    .string()
    .url('Formato URL non valido')
    .required('Il campo URL è richiesto'),
  image: yup.mixed(),
});

import * as yup from 'yup';

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(5, 'La Password deve essere di almeno 5 caratteri')
    .required('Il campo Password è richiesto'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'Password diverse')
    .required('Questo campo è richiesto'),
  email: yup.string(),
  // .email('Formato email non valido')
  // .required('Il campo Email è richiesto'),
  code: yup.string().required('Il campo Codice è richiesto'),
});

export const resetLinkSchema = yup.object().shape({
  email: yup
    .string()
    .email('Formato email non valido')
    .required('Il campo Email è richiesto'),
});

export const unsuscribeSchema = yup.object().shape({
  password: yup
    .string()
    .required('Il campo Password è richiesto'),
  email: yup
    .string()
    .required('Il campo Password è richiesto'),
});
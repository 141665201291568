import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { get, isEmpty } from 'lodash';
import { useState } from 'react';
import { get as getApi } from '../../api';
import './style.css';
import { ICompany, IPaymentHistory } from '../../types/company';
import moment from 'moment';

type PaginationParams = {
  page: number;
  pageSize: number;
};

type PaymentsQueryKey = [string, PaginationParams];

interface FetchAdsArgs {
  queryKey: PaymentsQueryKey;
}

type PaymentHistoryProps = {
  company: ICompany;
};

const PaymentsHistory = ({ company }: PaymentHistoryProps) => {
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  const fetchPayments = async ({ queryKey }: FetchAdsArgs) => {
    const [_key, { page, pageSize }] = queryKey;
    const response = await getApi(
      `/payment/all/${company.id}?page=${page + 1}&take=${pageSize}`
    );

    return response;
  };

  const { data, isLoading, status, error, refetch } = useQuery(
    ['payments', paginationModel],
    fetchPayments
  );
  // get specific property inside the data object from useQuery
  const payments = get(data, 'data.data', []);

  const formattedPayments = payments.map((payment: IPaymentHistory) => {
    let updatedPayment = { ...payment };
    updatedPayment.created = moment(payment.created).format('DD/MM/YYYY');
    return updatedPayment;
  });

  const buildRows = (data: ICompany[]) => {
    const rows: ICompany[] = !isEmpty(data) ? data : [];
    return rows;
  };

  const columns: GridColDef[] = [
    {
      field: 'created',
      headerName: 'Data',
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'importo',
      headerName: 'Importo (€)',
      headerAlign: 'center',
      align: 'center',
      width: 110,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'type',
      headerName: 'Tipologia',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'counter',
      headerName: 'Numero',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
  ];

  return (
    <div
      style={{
        overflow: 'auto',
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',

        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <div>
        {isLoading ? (
          <motion.div
            className="box"
            animate={{
              scale: [1, 2, 2, 1, 1],
              rotate: [0, 0, 180, 180, 0],
              borderRadius: ['25%', '35%', '50%', '50%', '20%'],
            }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              times: [0, 0.15, 0.35, 0.5, 0.7],
              repeat: Infinity,
              repeatDelay: 0.1,
            }}
          />
        ) : (
          <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.5,
              delay: 0.05,
              ease: [0, 0.71, 0.5, 0.8],
            }}
          >
            <div>
              <DataGrid
                autoHeight
                rows={buildRows(formattedPayments)}
                columns={columns}
                className="hide-focus"
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={(newModel) => {
                  setPaginationModel(newModel);
                }}
                pageSizeOptions={[5, 10, 25, 50]}
                style={{
                  borderRadius: 12,
                  padding: 12,
                  border: '1px solid #FFF',
                  backgroundColor: 'var(--clr-white)',
                }}
                sx={{
                  '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-columnHeader:focus-within': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-iconSeparator': {
                    display: 'none',
                  },
                }}
                rowCount={data?.data?.total}
              />
            </div>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default PaymentsHistory;

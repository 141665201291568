import { Dialog, DialogContent, DialogTitle } from '@mui/material';

type CustomDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  children: any;
  minWidth?: string;
};

const CustomDialog = ({
  open,
  onClose,
  title,
  children,
  minWidth = '450px',
}: CustomDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div style={{ width: `100%`, minWidth }}>
          <div>{children}</div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CustomDialog;

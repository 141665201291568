import AddRoundedIcon from '@mui/icons-material/AddRounded';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Button, IconButton } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import { get, isEmpty } from 'lodash';
import { useState } from 'react';
import { get as getApi } from '../../api';
import Add from './add';
import './style.css';
import { ICompany } from '../../types/company';
import PaymentsDialog from './payments';
import CertifiedDialog from './certified';
import CustomDialog from '../../components/Dialog/Dialog';
import PaymentsHistory from './history';
import PackageDialog from './package';
type PaginationParams = {
  page: number;
  pageSize: number;
};

type AdsQueryKey = [string, PaginationParams];

interface FetchAdsArgs {
  queryKey: AdsQueryKey;
}

const List = () => {
  const [openPaymentsHistory, setOpenPaymentsHistory] =
    useState<boolean>(false);
  const [openPayment, setOpenPayment] = useState<boolean>(false);
  const [openCertified, setOpenCertified] = useState<boolean>(false);
  const [openPackage, setOpenPackage] = useState<boolean>(false);
  const [openCreate, setOpenCreate] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  const fetchAds = async ({ queryKey }: FetchAdsArgs) => {
    const [_key, { page, pageSize }] = queryKey;
    const response = await getApi(
      `/adminUser/company/all?page=${page + 1}&take=${pageSize}`
    );
    return response;
  };

  const { data, isLoading, status, error, refetch } = useQuery(
    ['ads', paginationModel],
    fetchAds
  );

  // get specific property inside the data object from useQuery
  const ads = get(data, 'data.data', []);

  const openPaymentDialog = (params: GridRenderCellParams<ICompany>) => {
    setSelectedCompany(get(params, 'row', null));
    setOpenPayment(true);
  };

  const openCertifiedDialog = (params: GridRenderCellParams<ICompany>) => {
    setSelectedCompany(get(params, 'row', null));
    setOpenCertified(true);
  };

  const openPackageDialog = (params: GridRenderCellParams<ICompany>) => {
    setSelectedCompany(get(params, 'row', null));
    setOpenPackage(true);
  };

  const closePaymentDialog = () => {
    setSelectedCompany(null);
    setOpenPayment(false);
  };

  const closeCertifiedDialog = () => {
    setSelectedCompany(null);
    setOpenCertified(false);
  };

  const closePackageDialog = () => {
    setSelectedCompany(null);
    setOpenPackage(false);
  };

  const openPaymentsHistoryDialog = (params: GridRenderCellParams<any>) => {
    setSelectedCompany(get(params, 'row', null));
    setOpenPaymentsHistory(true);
  };

  const closeCreateDialog = () => setOpenCreate(false);

  const closePaymentsHistoryDialog = () => {
    setSelectedCompany(null);
    setOpenPaymentsHistory(false);
  };

  const buildRows = (data: ICompany[]) => {
    const rows: ICompany[] = !isEmpty(data) ? data : [];
    return rows;
  };

  const columns: GridColDef[] = [
    {
      field: 'ragioneSociale',
      headerName: 'Ragione Sociale',
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'partitaIva',
      headerName: 'Partita Iva',
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 250,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },

    {
      field: 'payments',
      headerName: 'Gestione crediti',
      headerAlign: 'center',
      width: 150,
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={() => openPaymentDialog(params)}>
            <AddShoppingCartIcon />
          </IconButton>
        </div>
      ),
    },
    {
      field: 'packages',
      headerName: 'Gestione pacchetti',
      headerAlign: 'center',
      width: 150,
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={() => openPackageDialog(params)}>
            <AddShoppingCartIcon />
          </IconButton>
        </div>
      ),
    },
    {
      field: 'certified',
      headerName: 'Gestione check',
      headerAlign: 'center',
      width: 150,
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={() => openCertifiedDialog(params)}>
            <AddShoppingCartIcon />
          </IconButton>
        </div>
      ),
    },
    {
      field: 'paymentsHistory',
      headerName: 'Storico pagamenti',
      headerAlign: 'center',
      width: 150,
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={() => openPaymentsHistoryDialog(params)}>
            <ReceiptLongIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div
      style={{
        overflow: 'auto',
        width: 'auto',
        display: 'flex',
        flexDirection: 'column',
        marginTop: 80,
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
    >
      <div>
        {isLoading ? (
          <motion.div
            className="box"
            animate={{
              scale: [1, 2, 2, 1, 1],
              rotate: [0, 0, 180, 180, 0],
              borderRadius: ['25%', '35%', '50%', '50%', '20%'],
            }}
            transition={{
              duration: 2,
              ease: 'easeInOut',
              times: [0, 0.15, 0.35, 0.5, 0.7],
              repeat: Infinity,
              repeatDelay: 0.1,
            }}
          />
        ) : (
          <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.5,
              delay: 0.05,
              ease: [0, 0.71, 0.5, 0.8],
            }}
          >
            <div
              style={{
                marginTop: 8,
                marginBottom: 24,
              }}
            >
              <Button
                onClick={() => setOpenCreate(true)}
                variant="contained"
                endIcon={<AddRoundedIcon />}
                sx={[
                  {
                    backgroundColor: 'var(--clr-inactive)',
                    borderRadius: 2,
                    color: 'var(--clr-white)',
                  },
                  {
                    '&:hover': {
                      backgroundColor: 'var(--clr-buildyou-primary)',
                    },
                  },
                ]}
              >
                Crea nuova Azienda
              </Button>
            </div>
            <div>
              <DataGrid
                autoHeight
                rows={buildRows(ads)}
                columns={columns}
                className="hide-focus"
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={(newModel) => {
                  setPaginationModel(newModel);
                }}
                pageSizeOptions={[5, 10, 25, 50]}
                style={{
                  borderRadius: 12,
                  padding: 12,
                  border: '1px solid #FFF',
                  backgroundColor: 'var(--clr-white)',
                }}
                sx={{
                  '& .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-columnHeader:focus-within': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-iconSeparator': {
                    display: 'none',
                  },
                }}
                rowCount={data?.data?.total}
              />
            </div>
          </motion.div>
        )}
      </div>
      {openCreate && (
        <Add
          open={openCreate}
          onClose={closeCreateDialog}
          refreshFn={refetch}
        />
      )}
      {selectedCompany && openPayment && (
        <PaymentsDialog
          company={selectedCompany}
          open={openPayment}
          onClose={closePaymentDialog}
          refreshFn={refetch}
        />
      )}
      {selectedCompany && (
        <CustomDialog
          open={openPaymentsHistory}
          onClose={closePaymentsHistoryDialog}
          title={'Storico pagamenti'}
        >
          <PaymentsHistory company={selectedCompany} />
        </CustomDialog>
      )}
      {selectedCompany && openCertified && (
        <CertifiedDialog
          company={selectedCompany}
          open={openCertified}
          onClose={closeCertifiedDialog}
          refreshFn={refetch}
        />
      )}
      {selectedCompany && openPackage && (
        <PackageDialog
          company={selectedCompany}
          open={openPackage}
          onClose={closePackageDialog}
          refreshFn={refetch}
        />
      )}
    </div>
  );
};

export default List;

import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  CardActions,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { get, isEmpty } from "lodash";
import { useState } from "react";
import {
  Controller,
  FieldErrors,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { post as postApi } from "../../api";
import { AlertMessage } from "../../types/form";
import { paymentCreateSchema } from "./schema";
import "./style.css";
import { ICompany, IPayment } from "../../types/company";
import { InputAdornment } from "@mui/material";

type EditDialogProps = {
  company: ICompany;
  open: boolean;
  onClose: () => void;
  refreshFn?: Function;
};

const Payments = ({ company, open, onClose, refreshFn }: EditDialogProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const createPaymentApi = (payload: IPayment) => postApi(`/payment`, payload);
  const mutation = useMutation(createPaymentApi);

  const {
    control,
    handleSubmit,
    formState: { errors },
    resetField,
  } = useForm<IPayment>({
    defaultValues: {
      crediti: 0,
      importo: 0,
    },
    mode: "onChange",
    resolver: yupResolver(paymentCreateSchema),
  });

  const onSubmit: SubmitHandler<IPayment> = async (data) => {
    const { crediti, importo } = data;

    const paymentPayload: IPayment = {
      crediti,
      importo,
      companyId: company?.id,
    };

    try {
      setLoading(true);

      const apiResponse = await createPaymentApi(paymentPayload);

      if (apiResponse.status >= 400) {
        setAlertMessage({
          severity: "error",
          message: "Unable to add Payment",
        });
        setShowAlert(true);
        throw new Error("Unable to create Payment");
      }

      setAlertMessage({
        severity: "success",
        message: "Payment created successfully",
      });
      setShowAlert(true);

      if (refreshFn) refreshFn();

      handleClose();
    } catch (error) {
      setAlertMessage({
        severity: "error",
        message: "Unable to create ad",
      });
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    resetField("crediti");
    resetField("importo");
    onClose();
  };

  const renderErrorSection = (errors: FieldErrors<IPayment>, name: string) => {
    const fieldError = get(errors, name, null);
    return !!fieldError ? (
      <div className="form-error-message">
        <div className="form-error-message">
          {get(fieldError, "message", "")}
        </div>
      </div>
    ) : null;
  };

  const renderCreateError = (responseError: Error | unknown) => {
    const status = get(responseError, "statusCode", 0);
    const message = get(responseError, "message", undefined);
    return !isEmpty(message) ? (
      <div className="error-message">
        <div className="error-message">Qualcosa è andato storto.</div>
      </div>
    ) : null;
  };

  const renderButton = (isLoading: boolean, errors: any) => {
    const content = isLoading ? (
      <CircularProgress size={20} />
    ) : (
      <span>Salva</span>
    );

    const isDisabled = isLoading || !isEmpty(errors);
    return (
      <Button
        sx={[
          {
            backgroundColor: "var(--clr-buildyou-primary)",
            borderRadius: 2,
            borderColor: "var(--clr-buildyou-primary)",
            color: "var(--clr-white)",
          },
          {
            "&:hover": {
              backgroundColor: "var(--clr-white)",
              borderColor: "var(--clr-buildyou-primary)",
              color: "var(--clr-buildyou-primary)",
            },
          },
        ]}
        fullWidth
        type="submit"
        onClick={handleSubmit(onSubmit)}
        disabled={isDisabled}
        variant="outlined"
      >
        {content}
      </Button>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Crea pagamento</DialogTitle>
        <DialogContent>
          <div style={{ width: `100%`, minWidth: `450px` }}>
            <div style={{ margin: `12px 4px` }}>
              <div className="form-field">
                <label>Crediti disponibili</label>
                <TextField
                  sx={{
                    marginTop: "12px",
                    marginBottom: "12px",
                    lineHeight: "24px",
                    fontWeight: 400,
                    fontSize: "14px",
                  }}
                  size="small"
                  value={company?.creditiDisponibili || "0"}
                  disabled
                  type="text"
                />
              </div>

              <div className="form-field">
                <label>Crediti </label>
                <Controller
                  name="crediti"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{
                        marginTop: "12px",
                        marginBottom: "12px",
                        lineHeight: "24px",
                        fontWeight: 400,
                        fontSize: "14px",
                      }}
                      size="small"
                      placeholder="Es. 30"
                      type="text"
                      {...field}
                    />
                  )}
                />
                {renderErrorSection(errors, "crediti")}
              </div>

              <div className="form-field">
                <label>Importo pagato</label>
                <Controller
                  name="importo"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      sx={{
                        marginTop: "12px",
                        marginBottom: "12px",
                        lineHeight: "24px",
                        fontWeight: 400,
                        fontSize: "14px",
                      }}
                      size="small"
                      placeholder="Inserisci l'importo del pagamento"
                      type="text"
                      {...field}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">€</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
                {renderErrorSection(errors, "importo")}
              </div>
              {renderCreateError(mutation.error)}
            </div>
          </div>
        </DialogContent>
        <CardActions>
          <Button
            sx={[
              {
                borderRadius: 2,
                borderColor: "var(--clr-inactive)",
                color: "var(--clr-inactive)",
              },
              {
                "&:hover": {
                  borderColor: "var(--clr-inactive)",
                  backgroundColor: "var(--clr-inactive)",
                  color: "var(--clr-white)",
                },
              },
            ]}
            fullWidth
            variant="outlined"
            onClick={handleClose}
          >
            Annulla
          </Button>
          {renderButton(mutation.isLoading, errors)}
        </CardActions>
        <Snackbar
          open={showAlert}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={4000}
          onClose={() => setShowAlert(false)}
        >
          <Alert severity={alertMessage?.severity}>
            {alertMessage?.message}
          </Alert>
        </Snackbar>
      </Dialog>
    </form>
  );
};

export default Payments;

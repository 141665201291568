export interface IUser {
  id: string;
  bio?: string;
  email: string;
  name: string;
  role: string;
  surname: string;
  disabledUntil: any;
  calendly?: string;
}

export const INDEFINITE_DISABLED_DATE = "9999-12-31";

export enum RoleNames {
  ADMIN = "admin",
  FEDRO = "fedro",
  CORPORATE = "corporate",
  USER = "user",
}

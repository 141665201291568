import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import SupervisorAccountRoundedIcon from "@mui/icons-material/SupervisorAccountRounded";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import CampaignIcon from "@mui/icons-material/Campaign";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { get as getApi } from "../../api";
import { logout } from "../../utils/auth";
import Logo from "../Logo";
import "./style.css";
import { AUTH_ROUTES } from "../../routes";
import { get, isEmpty } from "lodash";
import GroupsRoundedIcon from "@mui/icons-material/GroupsRounded";
import { RoleNames } from "../../types/user";

const Layout = ({ children }: any) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(true);

  const { data } = useQuery({
    queryKey: ["me"],
    queryFn: () => getApi("adminUser/me"),
    staleTime: Infinity,
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
  });

  const me = get(data, "data", {});

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getDrawerWidth = () => {
    return collapsed ? 59 : 240;
  };

  const selectActiveLink = (key: string) => {
    const activity = !isEmpty(
      AUTH_ROUTES.find((r) => r.id === key && r.path === pathname)
    )
      ? "active"
      : "inactive";
    return `menu-item menu-item--${activity}`;
  };

  return (
    <section className="dashboard">
      <header className="header">
        <div className="header__item">
          <Logo />
        </div>
        <nav>
          <div className="menu">
            <ul className="menu__list">
              {/* <li className="menu__item">
                <div className={selectActiveLink('statistics')}>
                  <span>
                    <BarChartRoundedIcon fontSize="inherit" />
                  </span>
                  <span className="menu-item__text">Statistics</span>
                </div>
              </li> */}
              {me?.role === RoleNames.ADMIN && (
                <>
                  <li className="menu__item">
                    <div
                      className={selectActiveLink("admin")}
                      onClick={() => navigate("/admin")}
                    >
                      <span style={{ marginLeft: "0px" }}>
                        <SupervisorAccountRoundedIcon fontSize="inherit" />
                      </span>
                      <span style={{ margin: "10px" }}>Admin Management</span>
                    </div>
                  </li>
                  <li className="menu__item">
                    <div
                      className={selectActiveLink("user")}
                      onClick={() => navigate("/user")}
                    >
                      <span style={{ marginLeft: "0px" }}>
                        <PeopleAltRoundedIcon fontSize="inherit" />
                      </span>
                      <span style={{ margin: "10px" }}>User Management</span>
                    </div>
                  </li>
                  <li className="menu__item">
                    <div
                      className={selectActiveLink("company")}
                      onClick={() => navigate("/company")}
                    >
                      <span>
                        <GroupsRoundedIcon fontSize="inherit" />
                      </span>
                      <span className="menu-item__text ">
                        Company Management
                      </span>
                    </div>
                  </li>
                  <li className="menu__item">
                    <div
                      className={selectActiveLink("ads")}
                      onClick={() => navigate("/ads")}
                    >
                      <span>
                        <CampaignIcon fontSize="inherit" />
                      </span>
                      <span className="menu-item__text ">Pubblicità </span>
                    </div>
                  </li>
                </>
              )}
              {me?.role === RoleNames.FEDRO && (
                <>
                  <li className="menu__item">
                    <div
                      className={selectActiveLink("coach")}
                      onClick={() => navigate("/coach")}
                    >
                      <span>
                        <SettingsRoundedIcon fontSize="inherit" />
                      </span>
                      <span className="menu-item__text ">Censimento Coach</span>
                    </div>
                  </li>

                  <li className="menu__item">
                    <div
                      className={selectActiveLink("challenge")}
                      onClick={() => navigate("/challenge")}
                    >
                      <span>
                        <SettingsRoundedIcon fontSize="inherit" />
                      </span>
                      <span className="menu-item__text ">Gestione sfide</span>
                    </div>
                  </li>
                </>
              )}
              <li className="menu__item">
                <div
                  className={selectActiveLink("logout")}
                  onClick={() => logout()}
                >
                  <span>
                    <LogoutRoundedIcon fontSize="inherit" />
                  </span>
                  <span className="menu-item__text ">Logout</span>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>
      <main className="dashboard__main">
        <section className="dashboard__section">
          <div>{children}</div>
        </section>
      </main>
    </section>
  );
};

export default Layout;

import './style.css';

const Logo = () => {
  return (
    <svg className='BY_Logo' xmlns="http://www.w3.org/2000/svg" width="164" height="44.924" viewBox="0 0 164 44.924">
      <defs>
      <style>
      </style>
      </defs>
      <path className="a"
            d="M250.425,351.256h-1.758v6.056H247.29v-6.056h-1.04l.612-1.284h.428a3.092,3.092,0,0,1,1.055-2.469,3.977,3.977,0,0,1,2.691-.91v1.284q-2.371,0-2.37,2.1h1.758Z"
            transform="translate(-217.047 -312.541)"/>
      <path className="a"
            d="M272.266,370.943v-4.174a3.472,3.472,0,0,1,.658-2.432,2.03,2.03,0,0,1,.78-.5,3.237,3.237,0,0,1,.8-.2q.344-.03.94-.031h1.346v1.284h-1.621c-.2,0-.362.006-.474.016a1.455,1.455,0,0,0-.4.107,1,1,0,0,0-.4.29,2.551,2.551,0,0,0-.291,1.468v4.174Z"
            transform="translate(-237.894 -326.172)"/>
      <path className="a"
            d="M299.967,363.95a3.554,3.554,0,0,1,2.676-1.116,3.6,3.6,0,0,1,1.46.3,3.812,3.812,0,0,1,2.286,3.525,3.916,3.916,0,0,1-.283,1.491,3.754,3.754,0,0,1-.787,1.216,3.738,3.738,0,0,1-5.352,0,3.751,3.751,0,0,1-.788-1.216,3.917,3.917,0,0,1-.283-1.491,3.7,3.7,0,0,1,1.07-2.707m2.676.168a2.232,2.232,0,0,0-1.72.7,2.916,2.916,0,0,0,0,3.67,2.455,2.455,0,0,0,3.448-.008,2.921,2.921,0,0,0,0-3.655,2.213,2.213,0,0,0-1.728-.711"
            transform="translate(-259.234 -325.556)"/>
      <path className="a"
            d="M345.08,363.6h6.743a3.163,3.163,0,0,1,1.728.413,1.938,1.938,0,0,1,.864,1.071,5.288,5.288,0,0,1,.207,1.575v4.281h-1.346v-4.281a1.616,1.616,0,0,0-.719-1.575,3.6,3.6,0,0,0-1.483-.2h-.627v6.056H349.1v-6.056h-2.676v6.056H345.08Z"
            transform="translate(-296.242 -326.172)"/>
      <path className="a"
            d="M425.173,371.1a4.676,4.676,0,0,1-1.514-1.758,9.459,9.459,0,0,1-.566-2.615h-.153a9.473,9.473,0,0,1-.565,2.615,4.685,4.685,0,0,1-1.514,1.758,3.676,3.676,0,0,1-1.675-1.881,9.329,9.329,0,0,1-.726-3.364l-.107-2.248h1.361l.122,2.187a6.722,6.722,0,0,0,.994,3.64,4.263,4.263,0,0,0,.428-.581,3.67,3.67,0,0,0,.336-.711q.137-.39.237-.688a5.732,5.732,0,0,0,.183-.765q.084-.466.115-.68t.084-.688q.053-.474.069-.535h1.468q.015.061.069.535t.084.688q.031.214.115.68a5.625,5.625,0,0,0,.184.765q.1.3.237.688a3.653,3.653,0,0,0,.337.711,4.252,4.252,0,0,0,.428.581,6.723,6.723,0,0,0,.994-3.64l.122-2.187h1.361l-.107,2.248a9.338,9.338,0,0,1-.726,3.364,3.677,3.677,0,0,1-1.675,1.881"
            transform="translate(-354.958 -326.172)"/>
      <path className="a"
            d="M472.138,359.225h-1.346v-6.056h-1.254l.612-1.285h1.988Zm-1.407-8.869a.911.911,0,0,1-.2-.566.879.879,0,0,1,.2-.558.867.867,0,0,1,1.223,0,.882.882,0,0,1,0,1.116.73.73,0,0,1-.612.252.74.74,0,0,1-.612-.244"
            transform="translate(-395.975 -314.453)"/>
      <path className="a"
            d="M492.937,354.469v-7.8h1.376v7.8a2.575,2.575,0,0,0,.13.895.874.874,0,0,0,.405.489,1.823,1.823,0,0,0,.535.191,4.1,4.1,0,0,0,.688.046v1.285a3.548,3.548,0,0,1-2.424-.657,2.965,2.965,0,0,1-.711-2.248"
            transform="translate(-414.725 -312.604)"/>
      <path className="a"
            d="M513.717,354.469v-7.8h1.376v7.8a2.569,2.569,0,0,0,.13.895.875.875,0,0,0,.405.489,1.823,1.823,0,0,0,.535.191,4.1,4.1,0,0,0,.688.046v1.285a3.55,3.55,0,0,1-2.424-.657,2.966,2.966,0,0,1-.711-2.248"
            transform="translate(-431.376 -312.604)"/>
      <path className="a"
            d="M548.583,359.028l2.737-2.737v1.453h2.064v1.284H551.32v3.15a1.976,1.976,0,0,0,.177.887,1.013,1.013,0,0,0,.511.5,2.608,2.608,0,0,0,.636.191,4.954,4.954,0,0,0,.741.046v1.284a4.128,4.128,0,0,1-2.6-.673,2.7,2.7,0,0,1-.842-2.233v-3.15Z"
            transform="translate(-459.316 -320.313)"/>
      <path className="a"
            d="M579.981,363.95a3.553,3.553,0,0,1,2.676-1.116,3.61,3.61,0,0,1,1.46.3,3.812,3.812,0,0,1,2.285,3.525,3.919,3.919,0,0,1-.282,1.491,3.776,3.776,0,0,1-.787,1.216,3.727,3.727,0,0,1-6.139-1.216,3.881,3.881,0,0,1-.284-1.491,3.7,3.7,0,0,1,1.071-2.707m2.676.168a2.23,2.23,0,0,0-1.72.7,2.917,2.917,0,0,0,0,3.67,2.456,2.456,0,0,0,3.449-.008,2.922,2.922,0,0,0,0-3.655,2.217,2.217,0,0,0-1.728-.711"
            transform="translate(-483.618 -325.556)"/>
      <path className="a"
            d="M647.418,363.6v1.284h-2.708a1.625,1.625,0,0,0-.763.169.52.52,0,0,0-.322.474.691.691,0,0,0,.078.329.853.853,0,0,0,.175.237,1.341,1.341,0,0,0,.306.191,2.844,2.844,0,0,0,.322.137q.113.038.971.313t1.268.459a1.51,1.51,0,0,1,.636.5,1.636,1.636,0,0,1,.282.612,2.862,2.862,0,0,1,.062.589q0,2.049-2.813,2.049h-2.646v-1.285h2.646q1.436,0,1.436-.764a.707.707,0,0,0-.419-.611,4.4,4.4,0,0,0-1.025-.413q-.6-.169-1.208-.39a2.406,2.406,0,0,1-1.025-.719,1.842,1.842,0,0,1-.421-1.231,1.633,1.633,0,0,1,.719-1.429,2.988,2.988,0,0,1,1.742-.5Z"
            transform="translate(-534.373 -326.172)"/>
      <path className="a"
            d="M681.73,350.035h1.484l-1.852,3.2q1.883.5,1.881,3.043v1.1H681.9v-1.116a2.055,2.055,0,0,0-.6-1.583,2.447,2.447,0,0,0-1.721-.558,5.76,5.76,0,0,0-.719.046v3.211H677.51v-10.7h1.345v6.284a4.553,4.553,0,0,1,1.285,0Z"
            transform="translate(-562.629 -312.604)"/>
      <path className="a" d="M713.971,370.943h-1.347v-6.056H711.37l.612-1.284h1.989Z"
            transform="translate(-589.762 -326.172)"/>
      <path className="a"
            d="M716.571,350.355a.91.91,0,0,1-.2-.566.878.878,0,0,1,.2-.558.868.868,0,0,1,1.224,0,.883.883,0,0,1,0,1.116.879.879,0,0,1-1.224.008"
            transform="translate(-593.769 -314.453)"/>
      <path className="a"
            d="M734.769,354.469v-7.8h1.377v7.8a2.605,2.605,0,0,0,.129.895.881.881,0,0,0,.405.489,1.83,1.83,0,0,0,.536.191,4.08,4.08,0,0,0,.687.046v1.285a3.549,3.549,0,0,1-2.424-.657,2.969,2.969,0,0,1-.711-2.248"
            transform="translate(-608.512 -312.604)"/>
      <path className="a"
            d="M755.55,354.469v-7.8h1.377v7.8a2.554,2.554,0,0,0,.131.895.87.87,0,0,0,.4.489,1.847,1.847,0,0,0,.536.191,4.079,4.079,0,0,0,.688.046v1.285a3.549,3.549,0,0,1-2.424-.657,2.969,2.969,0,0,1-.711-2.248"
            transform="translate(-625.165 -312.604)"/>
      <path className="b"
            d="M336.753,251.169a.158.158,0,0,0,.158.158h4.644a.158.158,0,0,0,.158-.158l0-17.694a.158.158,0,0,0-.158-.159,9.6,9.6,0,0,1-2.344.34,9.02,9.02,0,0,1-2.3-.34.172.172,0,0,0-.166.159Z"
            transform="translate(-289.566 -221.769)"/>
      <path className="b"
            d="M336.516,188.7a2.892,2.892,0,0,0-2.407,1.037,3.358,3.358,0,0,0-.75,2.121,3.465,3.465,0,0,0,.757,2.151,3.338,3.338,0,0,0,4.808-.032,3.37,3.37,0,0,0,0-4.24,2.894,2.894,0,0,0-2.407-1.037"
            transform="translate(-286.849 -186.02)"/>
      <path className="b"
            d="M896.117,292.329a2.893,2.893,0,0,0-2.408,1.037,3.366,3.366,0,0,0-.749,2.121,3.466,3.466,0,0,0,.757,2.15,3.337,3.337,0,0,0,4.808-.031,3.364,3.364,0,0,0,0-4.24,2.889,2.889,0,0,0-2.406-1.037"
            transform="translate(-735.276 -269.058)"/>
      <path className="b"
            d="M117.391,186.711a10.381,10.381,0,0,0-7.564-2.961,10.592,10.592,0,0,0-5.13,1.293l-.4.217v-9.808a.158.158,0,0,0-.158-.158H99.414a.158.158,0,0,0-.159.158v19.072a10.885,10.885,0,0,0,2.813,7.817,9.755,9.755,0,0,0,7.356,2.958,11.281,11.281,0,0,0,7.823-2.82,10.216,10.216,0,0,0,3.153-7.955,10.528,10.528,0,0,0-3.01-7.813M113.873,199a5.547,5.547,0,0,1-4.167,1.5,5.113,5.113,0,0,1-3.97-1.526,6.324,6.324,0,0,1-1.441-4.449v-3.6a.249.249,0,0,1,.029-.054l.038-.052a1.98,1.98,0,0,1,.156-.173c.05-.051.112-.109.186-.175s.16-.137.262-.217a5.088,5.088,0,0,1,.537-.39,7.642,7.642,0,0,1,3.921-1.309,5.861,5.861,0,0,1,4.316,1.59,5.8,5.8,0,0,1,1.62,4.385A6.145,6.145,0,0,1,113.873,199"
            transform="translate(-99.255 -175.274)"/>
      <path className="b"
            d="M234.344,235.669a.158.158,0,0,1-.158.158h-3.723a4.293,4.293,0,0,1-1.762-.32,3.08,3.08,0,0,1-1.048-.708,2.8,2.8,0,0,1-.539-1.03,5.59,5.59,0,0,1-.252-1.136c-.025-.312-.039-.706-.039-1.171V220.042a.158.158,0,0,0-.158-.158h-4.643a.158.158,0,0,0-.158.158V231.5a16.644,16.644,0,0,0,.49,4.481,5.769,5.769,0,0,0,2.475,3.189c1.423.977,3.648,1.453,6.8,1.453h7.516a.158.158,0,0,0,.158-.159V220.042a.158.158,0,0,0-.158-.158H234.5a.158.158,0,0,0-.159.158Z"
            transform="translate(-197.505 -211.006)"/>
      <path className="b"
            d="M392.679,199.986a4.144,4.144,0,0,1-1.2-.432h0a1.6,1.6,0,0,1-.734-.912,6.125,6.125,0,0,1-.3-2.1V175.452a.159.159,0,0,0-.158-.158h-4.724a.158.158,0,0,0-.158.158v21.085c0,2.985.706,5.151,2.1,6.439s3.643,1.919,6.863,1.919h.709v-4.8h-.709a10.18,10.18,0,0,1-1.688-.11"
            transform="translate(-328.556 -175.274)"/>
      <path className="b"
            d="M459.69,175.293h-4.724a.158.158,0,0,0-.158.159v9.812l-.018-.01a11.481,11.481,0,0,0-4.882-1.494,9.965,9.965,0,0,0-7.756,3.035,10.267,10.267,0,0,0-3.047,7.527,10.42,10.42,0,0,0,2.94,7.562,10.248,10.248,0,0,0,7.634,3.011H459.69a.158.158,0,0,0,.158-.158V175.452a.159.159,0,0,0-.158-.159m-5.041,24.8h-4.971a4.952,4.952,0,0,1-3.922-1.537,6.05,6.05,0,0,1-1.41-4.237,5.868,5.868,0,0,1,1.481-4.207,4.653,4.653,0,0,1,3.568-1.565,7.821,7.821,0,0,1,4.255,1.316,13.782,13.782,0,0,1,1.155.815v9.258a.158.158,0,0,1-.158.158"
            transform="translate(-371.588 -175.273)"/>
      <path className="b"
            d="M683.334,221h0a10.759,10.759,0,0,0-3.359-2.3,10.234,10.234,0,0,0-4.13-.845,10.073,10.073,0,0,0-7.549,3.15,10.472,10.472,0,0,0-3.024,7.625,11.078,11.078,0,0,0,.8,4.193,10.634,10.634,0,0,0,2.223,3.426,10.573,10.573,0,0,0,15.109,0,10.656,10.656,0,0,0,2.221-3.427,11.073,11.073,0,0,0,.8-4.193A10.8,10.8,0,0,0,683.334,221m-7.492,1.65a5.1,5.1,0,0,1,4.023,1.638,6.983,6.983,0,0,1,0,8.673,5.759,5.759,0,0,1-8.032.013,6.979,6.979,0,0,1,0-8.7,5.151,5.151,0,0,1,4.009-1.624"
            transform="translate(-552.82 -209.378)"/>
      <path className="b"
            d="M800.362,220.04v15.627a.158.158,0,0,1-.159.158h-3.725a4.289,4.289,0,0,1-1.76-.319,3.094,3.094,0,0,1-1.049-.708,2.778,2.778,0,0,1-.538-1.029,5.648,5.648,0,0,1-.253-1.135c-.026-.316-.038-.71-.038-1.173V220.04a.159.159,0,0,0-.159-.159h-4.645a.157.157,0,0,0-.157.159V231.5a16.99,16.99,0,0,0,.489,4.481,5.769,5.769,0,0,0,2.475,3.19c1.422.977,3.648,1.453,6.8,1.453h7.516a.157.157,0,0,0,.157-.158V220.04a.157.157,0,0,0-.157-.159h-4.645a.157.157,0,0,0-.157.159"
            transform="translate(-651.072 -211.003)"/>
      <path className="b"
            d="M901.26,175.451a.159.159,0,0,0-.159-.158h-4.643a.159.159,0,0,0-.159.158v20.427c0,.087.062.131.149.131a7.591,7.591,0,0,1,2.344-.361,7.085,7.085,0,0,1,2.3.361c.078,0,.175-.044.175-.131Z"
            transform="translate(-737.952 -175.273)"/>
      <path className="b"
            d="M601.687,188.64a2.893,2.893,0,0,0-2.408,1.037,3.364,3.364,0,0,0-.749,2.12,3.465,3.465,0,0,0,.757,2.151,3.337,3.337,0,0,0,4.808-.032,3.367,3.367,0,0,0,0-4.239,2.9,2.9,0,0,0-2.408-1.037"
            transform="translate(-499.34 -185.969)"/>
      <path className="b"
          d="M582.412,175.194h-2.974a1.875,1.875,0,0,0-1.929,1.869s.01,3.1.01,3.477a6.2,6.2,0,0,1-1.5,4.391,4.966,4.966,0,0,1-3.947,1.623,5.173,5.173,0,0,1-3.962-1.647,6.125,6.125,0,0,1-1.492-4.368c0-.377.008-3.477.008-3.477a1.872,1.872,0,0,0-1.929-1.869h-2.974a.16.16,0,0,0-.157.158v5.176a12.1,12.1,0,0,0,.8,4.3,10.5,10.5,0,0,0,2.205,3.4,10,10,0,0,0,3.244,2.269,9.1,9.1,0,0,0,1.566.548l-.016,13.522a.17.17,0,0,0,.157.158h5.114a.17.17,0,0,0,.159-.158V191.043a8.908,8.908,0,0,0,1.547-.535,10.524,10.524,0,0,0,5.438-5.676,11.323,11.323,0,0,0,.783-4.3v-5.176a.161.161,0,0,0-.159-.158"
          transform="translate(-469.723 -175.194)"/>
    </svg>
  );
}

export default Logo;
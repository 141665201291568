import {
  Alert,
  Button,
  CardActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { get } from 'lodash';
import { useState } from 'react';
import { del } from '../../api';
import LoadingButton from '../../components/Loading/button';
import { AlertMessage } from '../../types/form';
import { IAds } from '../../types/ads';

type DeleteModalProps = {
  ad: IAds;
  open: boolean;
  onClose: () => void;
  refreshFn?: Function;
};

const Delete = ({ open, onClose, ad, refreshFn }: DeleteModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const deleteApi = (id: string) => del(`/ads/${id}`, {});
  const mutation = useMutation(deleteApi);

  const handleSubmit = async () => {
    if (!ad.id) return;
    mutation.mutate(ad.id, {
      onSuccess: (data) => {
        const status: number = get(data, 'response.status', 0);
        if (status >= 400) {
          setAlertMessage({
            severity: 'error',
            message: 'Errore',
          });
          setShowAlert(true);
          throw new Error('Unable to remove ad');
        }
        setAlertMessage({
          severity: 'success',
          message: 'Pubblicità rimossa con successo.',
        });
        setShowAlert(true);
        if (refreshFn) refreshFn();
        handleClose();
      },
    });
  };

  const handleClose = () => onClose();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Rimuovi Pubblicità</DialogTitle>
      <DialogContent>
        <div style={{ width: `100%`, minWidth: `450px` }}>
          <div style={{ margin: `12px 4px` }}>
            <label>Sei sicuro di voler rimuovere questa Pubblicità?</label>
          </div>
        </div>
      </DialogContent>
      <CardActions>
        <Button
          sx={[
            {
              backgroundColor: 'var(--clr-inactive)',
              borderRadius: 2,
              color: 'var(--clr-white)',
            },
            {
              '&:hover': {
                backgroundColor: 'var(--clr-inactive)',
              },
            },
          ]}
          fullWidth
          variant="outlined"
          onClick={handleClose}
        >
          Annulla
        </Button>
        <LoadingButton
          sx={[
            {
              backgroundColor: 'var(--clr-buildyou-primary)',
              borderRadius: 2,
              color: 'var(--clr-white)',
            },
            {
              '&:hover': {
                backgroundColor: 'var(--clr-buildyou-primary)',
              },
            },
          ]}
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          loading={mutation.isLoading}
        >
          SÌ
        </LoadingButton>
      </CardActions>
      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={4000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </Dialog>
  );
};

export default Delete;

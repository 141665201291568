import * as yup from 'yup';

export const schema = yup
  .object({
    name: yup.string().required('Il campo Nome è richiesto'),
    surname: yup.string().required('Il campo Cognome è richiesto'),
    email: yup
      .string()
      .email('Formato email non valido')
      .required('Il campo Email è richiesto'),
  })
  .required();


export const calendly = yup
  .object({
    calendly: yup.string().required('Il campo Calendly è richiesto'),
  })
  .required();

import './style.css';
import List from './list';

const AdsPage = () => {
  return (
    <div className="main-content">
      <List />
    </div>
  );
};

export default AdsPage;

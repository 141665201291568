import EditIcon from "@mui/icons-material/Edit";
import { Button, IconButton } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";
import { get, isEmpty } from "lodash";
import { useState } from "react";
import { get as getApi } from "../../api";
import Edit from "./edit";
import "./style.css";

interface IChallenge {
  id: string;
  email: string;
  name: string;
  surname: string;
  role: string;
  actions?: any;
}

type PaginationParams = {
  page: number;
  pageSize: number;
};

type ChallengesQueryKey = [string, PaginationParams];

interface FetchChallengesArgs {
  queryKey: ChallengesQueryKey;
}

const List = () => {
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>({});

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  });

  const fetchChallenges = async ({ queryKey }: FetchChallengesArgs) => {
    const [_key, { page, pageSize }] = queryKey;
    const response = await getApi(
      `/challenge/certified/all?page=${page + 1}&take=${pageSize}`
    );

    return response;
  };

  const { data, isLoading, status, error, refetch } = useQuery(
    ["challenges", paginationModel],
    fetchChallenges,
    {
      staleTime: 0,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    }
  );

  // get specific property inside the data object from useQuery
  const challenges = get(data, "data.data", []);

  const changeCoach = (params: GridRenderCellParams<IChallenge>) => {
    setSelectedRow(get(params, "row", {}));
    setOpenEdit(true);
  };

  const closeEditDialog = () => setOpenEdit(false);

  const buildRows = (data: IChallenge[]) => {
    const rows: IChallenge[] = !isEmpty(data) ? data : [];
    return rows;
  };

  const columns: GridColDef[] = [
    {
      field: "goal",
      headerName: "Goal",
      width: 250,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "updatedAt",
      headerName: "Ultima modifica",
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "public",
      headerName: "Public",
      width: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      field: "owner",
      headerName: "Creator",
      width: 200,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.owner?.email || "",
    },
    {
      field: "coach",
      headerName: "Coach",
      width: 150,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: (params) => {
        const coach = params.row.coach;
        if (coach) {
          return `${coach.name || ""} ${coach.surname || ""}`.trim();
        }
        return "";
      },
    },
    {
      field: "actions",
      headerName: "Gestisci",
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <IconButton onClick={() => changeCoach(params)}>
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div
      style={{
        overflow: "auto",
        width: "auto",
        display: "flex",
        flexDirection: "column",
        marginTop: 80,
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <div>
        {isLoading ? (
          <motion.div
            className="box"
            animate={{
              scale: [1, 2, 2, 1, 1],
              rotate: [0, 0, 180, 180, 0],
              borderRadius: ["25%", "35%", "50%", "50%", "20%"],
            }}
            transition={{
              duration: 2,
              ease: "easeInOut",
              times: [0, 0.15, 0.35, 0.5, 0.7],
              repeat: Infinity,
              repeatDelay: 0.1,
            }}
          />
        ) : (
          <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.5,
              delay: 0.05,
              ease: [0, 0.71, 0.5, 0.8],
            }}
          >
            <div>
              <DataGrid
                autoHeight
                rows={buildRows(challenges)}
                columns={columns}
                className="hide-focus"
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={(newModel) => {
                  setPaginationModel(newModel);
                }}
                pageSizeOptions={[5, 10, 25, 50]}
                style={{
                  borderRadius: 12,
                  padding: 12,
                  border: "1px solid #FFF",
                  backgroundColor: "var(--clr-white)",
                }}
                sx={{
                  "& .MuiDataGrid-cell:focus-within": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-columnHeader:focus-within": {
                    outline: "none",
                  },
                  "& .MuiDataGrid-iconSeparator": {
                    display: "none",
                  },
                }}
                rowCount={data?.data?.total}
              />
            </div>
          </motion.div>
        )}
      </div>
      {openEdit && (
        <Edit
          open={openEdit}
          onClose={closeEditDialog}
          refreshFn={refetch}
          selectedChallenge={selectedRow}
        />
      )}
    </div>
  );
};

export default List;

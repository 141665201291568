import { TextField, Chip, InputAdornment } from '@mui/material';
import { useEffect, useRef } from 'react';

type SearchBarProps = {
  onSearch: Function;
  selectedItems: User[];
  onItemClick: (user: User) => void;
  singleSelect?: boolean;
  searchTerm?: string;
  focus?: boolean;
};

type User = {
  avatar: null | string;
  companyAccount: boolean;
  email: string;
  id: string;
  isCoach: boolean;
  name: string;
  surname: string;
};

const SearchBar = ({
  onSearch,
  selectedItems,
  onItemClick,
  singleSelect,
  searchTerm,
  focus = true,
}: SearchBarProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [focus]);
  return (
    <TextField
      inputRef={inputRef}
      sx={{
        marginTop: '12px',
        marginBottom: '12px',
        lineHeight: '24px',
        fontWeight: 400,
        fontSize: '14px',
      }}
      size="small"
      fullWidth
      placeholder={
        singleSelect && selectedItems.length > 0 ? '' : 'Cerca Utente...'
      }
      type="text"
      disabled={singleSelect && selectedItems.length > 0 ? true : false}
      onChange={(e) => {
        onSearch(e.target.value);
      }}
      value={searchTerm}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            {selectedItems.map((item: User) => (
              <Chip
                key={item.id}
                label={`${item.name} ${item.surname}`}
                onDelete={() => onItemClick(item)}
                color="primary"
                style={{
                  backgroundColor: 'var(--clr-buildyou-primary)',
                  margin: '0 4px',
                }}
              />
            ))}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchBar;

import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { get as _get } from 'lodash';
import { logout } from '../utils/auth';

interface ApiConfig {
  customHeaders?: any;
  baseURL?: string;
}

export interface IAPIRequestError {
  error: string;
  message: string;
  status?: number;
  redirect?: string;
  raw?: object;
}

const handleAPIError = (error: any) => {
  const codes = [500, 404];
  if (codes.includes(_get(error, 'response.status', ''))) {
    console.error(_get(error, 'response.message', ''));
  }
};

const api = ({ baseURL, customHeaders }: ApiConfig) => {
  const token = localStorage.getItem('token');
  const headers = {
    ...(token && { Authorization: `Bearer ${token}` }),
    'Content-Type': 'application/json',
    ...customHeaders,
  };

  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000',
    headers,
  });

  instance.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem('token');
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  });

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { response, config } = error;
      const prevRequest = config;
      if (response.status === 401 && !prevRequest?.sent) {
        let refreshToken = localStorage.getItem('refresh');
        if (refreshToken) {
          try {
            //try refreshing token
            const data = await instance.post('/desktopAuth/refresh', {
              token: refreshToken,
            });
            if (_get(data, 'response.status', 0) >= 400)
              throw new Error('Error in refreshing token');
            const newToken = _get(data, 'data.authorization', '');
            if (newToken) {
              const newRefresh = _get(data, 'data.refresh', '');
              localStorage.setItem('token', newToken);
              newRefresh && localStorage.setItem('refresh', newRefresh);
              config.headers['Authorization'] = `Bearer ${newToken}`;
              return instance(config);
            }
          } catch (e) {
            console.error(e);
            logout();
          }
        } else {
          window.location.replace(window.location.origin + '/login');
        }
      }
      return error;
    }
  );
  return instance;
};

// https://github.com/microsoft/TypeScript/issues/6283
// typescript cannot differentiate promise reject type
/* eslint prefer-promise-reject-errors: 0 */
const handleReturnError = (err: AxiosError): Promise<never> =>
  Promise.reject({
    message: _get(err, 'response.data.message'),
    error: _get(err, 'response.data.error'),
    status: _get(err, 'response.status'),
    redirect: _get(err, 'response.data.redirect'),
    raw: _get(err, 'response.data') || err,
  } as unknown as IAPIRequestError);

// add post, put, patch for extension
// customHeaders is basically type any since it'll depend on backend architecture requirement
// e.g. 'Device-Type' 'Content-Type'
// type ANY because it's literally anything currently
export const get = async (
  url: string,
  customHeaders?: any,
  axiosConfig?: AxiosRequestConfig
) => {
  return api({ customHeaders })
    .get(url, axiosConfig)
    .catch((error) => {
      handleAPIError(error);
      return handleReturnError(error);
    });
};

export const post = async (
  url: string,
  data: any,
  customHeaders?: any,
  axiosConfig?: AxiosRequestConfig
) => {
  return api({ customHeaders })
    .post(url, data, axiosConfig)
    .catch((error) => {
      console.error(error);
      handleAPIError(error);
      return handleReturnError(error);
    });
};

export const put = async (
  url: string,
  data: any,
  customHeaders?: any,
  axiosConfig?: AxiosRequestConfig
) => {
  return api({ customHeaders })
    .put(url, data, axiosConfig)
    .catch((error) => {
      console.error(error);
      handleAPIError(error);
      return handleReturnError(error);
    });
};

export const del = async (
  url: string,
  customHeaders?: any,
  axiosConfig?: AxiosRequestConfig
) => {
  return api({ customHeaders })
    .delete(url, axiosConfig)
    .catch((error) => {
      console.error(error);
      handleAPIError(error);
      return handleReturnError(error);
    });
};

import {
  Alert,
  Button,
  CardActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { get } from 'lodash';
import { useState } from 'react';
import { put as putApi } from '../../api';
import LoadingButton from '../../components/Loading/button';
import { AlertMessage } from '../../types/form';
import { HIDING_TIME_DIALOG, HIDING_TIME_ERROR } from '../../utils/constants';

type DeleteModalProps = {
  open: boolean;
  onClose: () => void;
  id: string;
  refreshFn?: Function;
};

const Delete = ({ open, onClose, id, refreshFn }: DeleteModalProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<AlertMessage>(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const deleteApi = (id: string) =>
    putApi(`/adminUser/update/coach`, { isCoach: false, id: [id] });
  const mutation = useMutation(deleteApi);

  const handleSubmit = async () => {
    if (!id) return;
    mutation.mutate(id, {
      onSuccess: (data) => {
        const status: number = get(data, 'response.status', 0);
        try {
          if (status >= 400) {
            throw new Error('Unable to remove Coach');
          } else {
            setAlertMessage({
              severity: 'success',
              message: 'Coach rimosso con successo',
            });
          }
        } catch (error) {
          setAlertMessage({
            severity: 'error',
            message: 'Errore',
          });
        } finally {
          setShowAlert(true);
          if (refreshFn) refreshFn();

          setTimeout(() => {
            setShowAlert(false);
          }, HIDING_TIME_ERROR);

          setTimeout(() => {
            handleClose();
          }, HIDING_TIME_DIALOG);
        }
      },
    });
  };

  const handleClose = () => onClose();

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Rimuovi Coach</DialogTitle>
      <DialogContent>
        <div style={{ width: `100%`, minWidth: `450px` }}>
          <div style={{ margin: `12px 4px` }}>
            <label>Sei sicuro di voler rimuovere questo Coach?</label>
          </div>
        </div>
      </DialogContent>
      <CardActions>
        <Button
          sx={[
            {
              backgroundColor: 'var(--clr-inactive)',
              borderRadius: 2,
              color: 'var(--clr-white)',
            },
            {
              '&:hover': {
                backgroundColor: 'var(--clr-inactive)',
              },
            },
          ]}
          fullWidth
          variant="outlined"
          onClick={handleClose}
        >
          Annulla
        </Button>
        <LoadingButton
          sx={[
            {
              backgroundColor: 'var(--clr-buildyou-primary)',
              borderRadius: 2,
              color: 'var(--clr-white)',
            },
            {
              '&:hover': {
                backgroundColor: 'var(--clr-buildyou-primary)',
              },
            },
          ]}
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          loading={mutation.isLoading}
        >
          SÌ
        </LoadingButton>
      </CardActions>
      <Snackbar
        open={showAlert}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={4000}
        onClose={() => setShowAlert(false)}
      >
        <Alert severity={alertMessage?.severity}>{alertMessage?.message}</Alert>
      </Snackbar>
    </Dialog>
  );
};

export default Delete;

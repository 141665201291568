import { get } from 'lodash';

interface ITokens {
  authorization: string;
  refresh: string;
}

export const setTokens = (tokens: ITokens) => {
  const { authorization, refresh } = tokens;
  localStorage.setItem('token', authorization);
  localStorage.setItem('refresh', refresh);
}

export const getLocalTokens = () => {
  const token = localStorage.getItem('token') ?? null;
  const refresh = localStorage.getItem('refresh') ?? null;
  return {
    token,
    refresh,
  }
}

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh');
  window.location.reload();
}